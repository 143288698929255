import React from 'react'
import CoinIcon from '../../shared/CoinIcon'
import ColoredPrice from '../../shared/ColoredPrice'
import PairHeading from '../../shared/pairs/PairHeading'

import PriceFormatter from '../../../lib/formatters/PriceFormatter'
import DeviationFormatter from '../../../lib/formatters/DeviationFormatter'
import PercentageFormatter from '../../../lib/formatters/PercentageFormatter'

export default class PairMarket extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      sortKey: 'pair_market_cap',
      sortOrder: true, // DESC
    }

    this.updateSort = this.updateSort.bind(this)
    this.sortIcon = this.sortIcon.bind(this)
    this.sortedProducts = this.sortedProducts.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...nextProps,
    }
  }

  renderDeviation(product) {
    if (!product.deviation || product.deviation_percent === 0) {
      return '--'
    }

    const formattedDevPct = new PercentageFormatter(
      product.deviation_percent
    ).result()

    if (this.props.mode === 'MOBILE') {
      return formattedDevPct
    }

    const formattedDev = new DeviationFormatter(product.deviation, {
      round: product.precision,
      format: product.convert_to,
    }).result()

    return `${formattedDev}(${formattedDevPct})`
  }

  updateSort(sortKey) {
    if (sortKey === this.state.sortKey) {
      this.setState({ ...this.state, sortOrder: !this.state.sortOrder })
    } else {
      this.setState({ ...this.state, sortKey, sortOrder: true })
    }
  }

  sortIcon(key) {
    if (key !== this.state.sortKey) {
      return <i className="icon-sort"></i>
    }

    if (this.state.sortOrder) {
      return (
        <i className="icon-sort_down">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      )
    }

    return (
      <i className="icon-sort_up">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    )
  }

  sortedProducts() {
    return this.state.products.sort((prev, next) => {
      if (!prev[this.state.sortKey]) {
        return 1
      }
      if (!next[this.state.sortKey]) {
        return -1
      }

      if (this.state.sortOrder) {
        return next[this.state.sortKey] - prev[this.state.sortKey]
      } else {
        return prev[this.state.sortKey] - next[this.state.sortKey]
      }
    })
  }

  marketCap(product) {
    const formatOptions = {
      format: product.total_base_amount == 1 ? null : 'hundredMillion',
      prefix: this.props.mode !== 'MOBILE',
      postfix: true,
      unit: product.unit,
    }

    return new PriceFormatter(product.pair_market_cap, formatOptions).result()
  }

  render() {
    return (
      <div className="overflow-x-scroll">
        <table className="w-full text-sm md:text-base">
          <thead>
            <tr className="text-center bg-slate-200 whitespace-nowrap">
              <th className="p-1 md:p-2 whitespace-nowrap">
                <span className="hidden md:block font-bold">順位</span>
              </th>
              <th className="p-1 md:p-2 whitespace-nowrap font-bold">通貨名</th>
              <th className="p-1 md:p-2 whitespace-nowrap font-bold">価格</th>
              <th
                className="p-1 md:p-2 whitespace-nowrap font-bold"
                onClick={() => this.updateSort('deviation_percent')}
              >
                24H変動比
                {this.sortIcon('deviation_percent')}
              </th>
              <th
                className="p-1 md:p-2 whitespace-nowrap font-bold"
                onClick={() => this.updateSort('pair_market_cap')}
              >
                時価総額
                {this.sortIcon('pair_market_cap')}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-slate-300 divide-solid">
            {this.sortedProducts().map((product, index) => {
              const formattedPrice = new PriceFormatter(
                product.last_close_bid,
                { round: product.precision, format: product.convert_to }
              ).result()

              return (
                <tr
                  key={product.underscored_code}
                  className={this.props.mode === 'DESKTOP' ? 'h-8' : ''}
                >
                  <td className="p-1 md:p-2 italic text-center whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="p-1 md:p-2 text-center md:flex text-blue-700">
                    <a
                      href={`/pair/${product.underscored_code}`}
                      className="md:mr-4"
                    >
                      <CoinIcon coinName={product.base} />
                    </a>
                    <PairHeading mode={this.props.mode} product={product} />
                  </td>
                  <ColoredPrice
                    price={parseFloat(product.last_close_bid)}
                    formattedPrice={formattedPrice}
                  />
                  <td
                    className={`text-center p-1 md:p-2 whitespace-nowrap ${
                      product.deviation > 0 ? 'text-plus' : 'text-minus'
                    }`}
                  >
                    {this.renderDeviation(product)}
                  </td>
                  <td className="p-1 md:p-2 text-center whitespace-nowrap">
                    {this.marketCap(product)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
