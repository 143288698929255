import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'url-polyfill'
import '../src/news'
import '../vendors/embedly/platform'

import ReactOnRails from 'react-on-rails'

import LargeChart from '../bundles/components/chart/LargeChart'
import MiniRateChart from '../bundles/components/chart/MiniRateChart'
import MiniRateChartTable from '../bundles/components/MiniRateChartTable'
import WholeRateChartTable from '../bundles/components/WholeRateChartTable'
import PairRateFrame from '../bundles/components/PairRateFrame'
import PairRateHeaderPC from '../bundles/components/PairRateHeader/pc'
import PairRateHeaderSP from '../bundles/components/PairRateHeader/sp'
import CoinIcon from '../bundles/components/shared/CoinIcon'
import TipIcon from '../bundles/components/shared/TipIcon'
import ChatFrame from '../bundles/components/chat/ChatFrame'
import PredictionBar from '../bundles/components/prediction/PredictionBar'
import PairMarketsTable from '../bundles/components/PairMarketsTable'
import PairStatusPC from '../bundles/components/PairStatus/pc'
import DeviationRankingTable from '../bundles/components/CurrencyRankingTable/deviation'
import HighLowRankingTable from '../bundles/components/CurrencyRankingTable/highLow'
import TradingVolumeRankingTable from '../bundles/components/CurrencyRankingTable/tradingVolume'
import SidebarPairsTable from '../bundles/components/SidebarPairsTable'
import SidebarPairsDeviation from '../bundles/components/SidebarPairsTable/deviation_up'

ReactOnRails.register({
  LargeChart,
  MiniRateChart,
  MiniRateChartTable,
  WholeRateChartTable,
  PairRateFrame,
  PairRateHeaderPC,
  PairRateHeaderSP,
  CoinIcon,
  TipIcon,
  ChatFrame,
  PredictionBar,
  PairMarketsTable,
  PairStatusPC,
  DeviationRankingTable,
  HighLowRankingTable,
  TradingVolumeRankingTable,
  SidebarPairsTable,
  SidebarPairsDeviation,
})
