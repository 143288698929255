import PropTypes from 'prop-types'
import React from 'react'

export default class TipIcon extends React.Component {
  static propTypes = {
    iconColor: PropTypes.string,
    message: PropTypes.string,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { isFocusTip: false }
    this.handleFocusTip = this.handleFocusTip.bind(this)
    this.handleLeaveTip = this.handleLeaveTip.bind(this)
    this.toggleFocusTip = this.toggleFocusTip.bind(this)
  }

  componentDidMount() {
    if (this.props.mode == 'phone') {
      document.addEventListener('touchend', this.toggleFocusTip, false)
    }
  }

  componentWillUnmount() {
    if (this.props.mode == 'phone') {
      document.removeEventListener('touchend', this.toggleFocusTip, false)
    }
  }

  handleFocusTip() {
    this.setState({ isFocusTip: true })
  }

  handleLeaveTip() {
    this.setState({ isFocusTip: false })
  }

  toggleFocusTip(e) {
    if (this.node.contains(e.target)) {
      this.setState({ isFocusTip: true })
    } else {
      this.setState({ isFocusTip: false })
    }
  }

  renderIcon() {
    const iconColor = this.props.iconColor || 'text-slate-500'
    const iconClassName = `self-center md:text-lg ${iconColor} icon-help-with-circle`

    return this.props.mode == 'pc' ? (
      <i
        className={iconClassName}
        onMouseOver={this.handleFocusTip}
        onMouseLeave={this.handleLeaveTip}
        key="inner-icon"
      />
    ) : (
      <i
        className={iconClassName}
        ref={(node) => (this.node = node)}
        key="inner-icon"
      />
    )
  }

  renderIconByMode() {
    return this.props.mode == 'phone' ? (
      <span className="tips_icon" key="icon">
        {this.renderIcon()}
      </span>
    ) : (
      this.renderIcon()
    )
  }

  renderTip() {
    return (
      <div
        className={`text-slate-500 w-52 text-left absolute z-50 top-10 right-0 w-52 p-2 bg-white border-solid border-slate-400 rounded-lg border text-sm ${
          this.state.isFocusTip ? '' : 'hidden'
        }`}
        key="tip"
      >
        {this.props.message}
      </div>
    )
  }

  render() {
    return [this.renderIconByMode(), this.renderTip()]
  }
}
