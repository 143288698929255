import React from 'react'
import I18n from './i18n'

const localizedTime = (timeUnit) => {
  return I18n.renderLocalized(timeUnit, I18n.localTimeShort)
}

const Tab = (props) => {
  return (
    <li className="cursor-pointer before:border-l before:border-solid before:border-slate-300 before:h-6">
      <input
        type="radio"
        name={props.name}
        id={props.text}
        value={props.text}
        onChange={props.onClick}
        className="cursor-pointer peer hidden"
        checked={props.checked}
      />
      <label
        htmlFor={props.text}
        className="mx-1 md:mx-1.5 cursor-pointer peer-checked:bg-blue-500 peer-checked:rounded peer-checked:text-white peer-checked:py-1 peer-checked:px-2"
      >
        {localizedTime(props.text) || props.text}
      </label>
    </li>
  )
}

export default Tab
