import React from 'react'
import CoinIcon from '../../shared/CoinIcon'
import I18n from '../shared/i18n'
import PairHeading from '../../shared/pairs/PairHeading'
import Util from '../shared/util'
import PriceFormatter from '../../../lib/formatters/PriceFormatter'

export default class TradingVolumeRanking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...nextProps,
    }
  }

  rankByTradingVolume() {
    return Util.sortedProducts({
      products: this.state.products,
      sortKey: this.state.sortKey,
    }).map((product, index) => {
      const formattedPrice = new PriceFormatter(product.ltp, {
        round: product.precision,
        format: product.convert_to,
      }).result()

      const timeUnit = this.props.timeUnit.toLowerCase()
      const deviation = Util.renderDeviation({
        product,
        dev: 'dev_24h',
        devPct: 'dev_pct_24h',
      })

      return (
        <tr key={product.underscored_code}>
          <td className="italic text-center whitespace-nowrap">{index + 1}</td>
          <td className="text-center whitespace-nowrap">
            <a href={`/pair/${product.underscored_code}`}>
              <CoinIcon coinName={product.base} />
            </a>
          </td>
          <td className="text-blue-700">
            <PairHeading mode={this.props.mode} product={product} />
          </td>
          <td className="p-1 proportional-nums whitespace-nowrap text-right">
            <div>{formattedPrice}</div>
            <div
              className={`${product.dev_24h > 0 ? 'text-plus' : 'text-minus'}`}
            >
              {this.props.mode === 'DESKTOP' ? deviation.inNum : ''}
            </div>
          </td>
          <td
            className={`text-right whitespace-nowrap ${
              product.dev_24h > 0 ? 'text-plus' : 'text-minus'
            }`}
          >
            <div className="leading-[4rem] pr-1 bg-yellow-50 font-bold">
              {deviation.inRate}
            </div>
          </td>
          <td className="proportional-nums whitespace-nowrap text-right">
            {Util.formatTradingVolume({
              mode: this.props.mode,
              product,
              quoted: this.props.quoted,
              volume: `vol_${timeUnit}`,
            })}
          </td>
        </tr>
      )
    })
  }

  renderRanked() {
    if (this.state.isLoading) {
      return Util.loadingPlaceholder({ colSpan: 6 })
    }

    return this.rankByTradingVolume()
  }

  render() {
    return (
      <div className="p-4 md:rounded-lg shadow bg-white -mx-4 md:mx-0">
        <div class="overflow-x-scroll">
          <table className="w-full text-sm md:text-base">
            <thead>
              <tr className="text-center bg-slate-200">
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  <span className="hidden md:block">順位</span>
                </th>
                <th
                  className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap"
                  colSpan="2"
                >
                  通貨名
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  現在値
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white font-bold whitespace-nowrap">
                  24H変動比
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  {I18n.renderLocalized(
                    this.props.timeUnit,
                    I18n.localTimeShort
                  )}
                  売買代金
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-slate-300 divide-solid">
              {this.renderRanked()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
