window.onload = () => {
  const button = document.getElementById('news-pairs-button')

  if (!button) {
    return
  }

  button.addEventListener('click', () => {
    button.parentNode.removeChild(button)

    document.getElementById('news-pairs').classList.remove('hidden')
  })
}
