import PropTypes from 'prop-types'
import React from 'react'

export default class RangeBar extends React.Component {
  static propTypes = {
    periods: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
  }

  renderPeriods() {
    return this.props.periods.map((period) => {
      const checked = period.default == true

      return (
        <li
          key={period.value}
          className="my-1 md:my-0 before:hidden md:before:inline-block before:border-l before:border-solid before:border-slate-300 before:h-full"
        >
          <input
            type="radio"
            name="period"
            id={`period_${period.value}`}
            className={'peer hidden'}
            value={period.value}
            onChange={this.props.onChange}
            defaultChecked={checked}
          />
          <label
            htmlFor={`period_${period.value}`}
            className="text-sm peer-checked:bg-blue-500 peer-checked:rounded peer-checked:text-white peer-checked:p-1 mx-1 md:mx-2 hover:cursor-pointer"
          >
            {period.name}
          </label>
        </li>
      )
    })
  }

  render() {
    return (
      <ul className="flex justify-end items-center whitespace-nowrap flex-wrap">
        <li className="text-sm text-slate-500 mr-1 md:mr-2 my-1 md:my-0 hidden md:block">
          期間
        </li>
        {this.renderPeriods()}
      </ul>
    )
  }
}
