const headers = (credentials) => {
  const defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (credentials == 'omit') {
    return defaultHeader
  }
  defaultHeader['X-CSRF-Token'] = document.querySelector(
    'meta[name="csrf-token"]'
  ).content

  return defaultHeader
}

const queryString = (params) => {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  return `${query.length ? '?' : ''}${query}`
}

const parseResponse = (response) => {
  setTimeout(() => null, 0)

  if (!response) {
    return {}
  }

  if (!response.ok) {
    return Promise.reject(response)
  }

  return response.json()
}

const handleError = (response) => {
  if (response.error) {
    throw new Error(response.error)
  }

  return response
}

export default {
  fetch: (url, params = {}, credentials = 'include') => {
    return fetch(`${url}${queryString(params)}`, {
      method: 'GET',
      headers: headers(credentials),
      credentials,
    })
      .then(parseResponse)
      .then(handleError)
  },
  post: (url, params = {}, credentials = 'include') => {
    return fetch(url, {
      method: 'POST',
      headers: headers(credentials),
      body: JSON.stringify(params),
      credentials,
    })
      .then(parseResponse)
      .then(handleError)
  },
}
