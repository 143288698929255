import PropTypes from 'prop-types'
import React from 'react'

import ExchangerRateListFrame from './ExchangerRateList/ExchangerRateListFrame'
import ExchangerRate from './ExchangerRateList/ExchangerRate'

import { subscribeRateChange, unsubscribeRateChange } from '../../socket'

export default class RelatedPairRateList extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    eventTrackingAction: PropTypes.string,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.tickMap = this.generateDefaultTickMap()
    this.state = { tickMap: this.tickMap }
    this.handleLoad = this.handleLoad.bind(this)
    this.handleRateUpdate = this.handleRateUpdate.bind(this)
  }

  generateDefaultTickMap() {
    const map = new Map()
    this.props.pair.related.forEach((pairExchange) => {
      const exchangeCode = pairExchange.exchange.code
      const pairCode = pairExchange.currency_pair.product_code

      map.set(
        `${exchangeCode}-${pairCode}`,
        this.parseTick(pairExchange.latest_tick)
      )
    })

    return map
  }

  parseTick(tick) {
    return {
      bid: parseFloat(tick.bid),
      ask: parseFloat(tick.ask),
      volume: parseFloat(tick.volume),
    }
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      return this.handleLoad()
    }

    window.addEventListener('load', this.handleLoad)
  }

  handleLoad() {
    this.props.pair.related.forEach((pairExchange) => {
      const exchangeCode = pairExchange.exchange.code
      const pairCode = pairExchange.currency_pair.product_code

      subscribeRateChange(
        exchangeCode,
        pairCode,
        this.handleRateUpdate(exchangeCode, pairCode)
      )
    })
  }

  handleRateUpdate(exchangeCode, pair) {
    return (data) => {
      this.tickMap.set(`${exchangeCode}-${pair}`, this.parseTick(data))
      this.setState({ tickMap: this.tickMap })
    }
  }

  componentWillUnmount() {
    this.props.pair.related.forEach((pairExchange) => {
      const exchangeCode = pairExchange.exchange.code
      const productCode = pairExchange.currency_pair.product_code

      unsubscribeRateChange(exchangeCode, productCode)
    })

    window.removeEventListener('load', this.handleLoad)
  }

  title() {
    return `${this.props.pair.name.split('/')[0]}FX(レバレッジ)・信用取引`
  }

  pairWithExchanges(pair, exchange, leverageType) {
    return {
      ...pair,
      currency_pairs_exchanges: [{ exchange }],
      leverageType,
    }
  }

  sortRelatedList(related) {
    const tickMap = this.state.tickMap

    return related
      .map((pairExchange) => {
        const leverageType = pairExchange.leverage_type
        const exchange = pairExchange.exchange
        const pair = pairExchange.currency_pair
        const key = `${exchange.code}-${pair.product_code}`
        const tick = tickMap.get(key)

        return { leverageType, pair, exchange, key, tick }
      })
      .sort((a, b) =>
        isNaN(a.tick.volume) ? 1 : a.tick.volume < b.tick.volume ? 1 : -1
      )
  }

  render() {
    return (
      <ExchangerRateListFrame title={this.title()} mode={this.props.mode}>
        {this.sortRelatedList(this.props.pair.related).map((e) => {
          return (
            <ExchangerRate
              pair={this.pairWithExchanges(e.pair, e.exchange, e.leverageType)}
              eventTrackingAction={this.props.eventTrackingAction}
              company={e.exchange.name}
              tick={e.tick}
              mode={this.props.mode}
              key={e.key}
            />
          )
        })}
      </ExchangerRateListFrame>
    )
  }
}
