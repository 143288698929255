import PropTypes from 'prop-types'
import React from 'react'

import CoinSwitchButton from '../shared/CoinSwitchButton'
import AreaSwitchButton from '../shared/AreaSwitchButton'
import RangeBar from '../shared/RangeBar'
import MiniRateChart from '../chart/MiniRateChart'
import { rangeUnitMap, rangeBarPeriods } from '../../constants/miniChart'

export default class WholeRateChartTable extends React.Component {
  static propTypes = {
    pairs: PropTypes.array,
    defaultRange: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    pageLimit: PropTypes.number,
    hintType: PropTypes.string,
    mode: PropTypes.string,
    areas: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        aka: PropTypes.string,
        default: PropTypes.bool,
      })
    ),
    inCountryPairCodes: PropTypes.arrayOf(PropTypes.string),
  }

  constructor(props) {
    super(props)

    const defaultRange = props.defaultRange || rangeBarPeriods[0].value
    this.state = {
      range: defaultRange,
      selected: 0,
      displayed: props.pageLimit,
      areaSelected: 0,
    }

    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAreaSwitch = this.handleAreaSwitch.bind(this)
    this.handleRangeBar = this.handleRangeBar.bind(this)
    this.handleMoreClick = this.handleMoreClick.bind(this)
  }

  handleSwitch(e) {
    this.setState({ selected: e })
  }

  handleAreaSwitch(e) {
    this.setState({ areaSelected: e })
  }

  handleRangeBar(e) {
    this.setState({ range: e.target.value })
  }

  handleMoreClick() {
    this.setState({ displayed: this.props.pairs.length })
  }

  renderChartTable() {
    const currentCoin = this.props.categories[this.state.selected]
    const currentArea = this.props.areas[this.state.areaSelected].aka
    const filteredPairs = this.props.pairs.filter((pair) => {
      if (currentArea == 'incountry') {
        return (
          pair.quoted == currentCoin &&
          this.props.inCountryPairCodes.includes(pair.base)
        )
      }

      return pair.quoted == currentCoin
    })

    return (
      <div>
        <div
          id="jpy_chart_box"
          className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4"
        >
          {this.renderCharts(filteredPairs.slice(0, this.state.displayed))}
        </div>
        {filteredPairs.length > this.state.displayed
          ? this.renderMoreButton()
          : ''}
      </div>
    )
  }

  renderCharts(pairs) {
    const rangeUnitPair = rangeUnitMap[this.state.range]

    return pairs.map((pair) => (
      <div key={pair.product_code}>
        <div className="relative overflow-hidden bg-white rounded-lg shadow">
          <MiniRateChart
            pair={pair}
            range={rangeUnitPair.range}
            unit={rangeUnitPair.unit}
            limit={rangeUnitPair.amount}
            mode={this.props.mode}
            key={pair.product_code}
          />
        </div>
      </div>
    ))
  }

  renderMoreButton() {
    return (
      <div className="my-4">
        <button
          className="leading-extra-tight p-2 w-full rounded-lg border border-blue-700 border-solid bg-slate-50 shadow font-bold text-center text-blue-700 hover:opacity-70"
          onClick={this.handleMoreClick}
        >
          チャートをもっと見る
        </button>
      </div>
    )
  }

  render() {
    const periods = rangeBarPeriods.map((period) => ({
      ...period,
      default: period.value == this.state.range,
    }))

    return (
      <div>
        <div className="chart_period md:flex mb-2 md:items-center md:justify-between">
          <div className="flex items-center gap-4 mb-3 md:mb-0">
            <CoinSwitchButton
              coins={this.props.categories}
              onSwitch={this.handleSwitch}
            />
            <AreaSwitchButton
              areas={this.props.areas}
              onSwitch={this.handleAreaSwitch}
              type="radio"
            />
          </div>
          <RangeBar periods={periods} onChange={this.handleRangeBar} />
        </div>
        {this.renderChartTable()}
      </div>
    )
  }
}
