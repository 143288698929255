import PropTypes from 'prop-types'
import React from 'react'

import TipIcon from '../shared/TipIcon'
import TickProvider from '../shared/TickProvider'

import chatApi from '../../api/chat'

const CHAT_ROOM = 1
const TREND_UP = 1
const TREND_DOWN = 0
const VOTE_FAILED_MESSAGE =
  '投稿ありがどうございます！予想投稿は1時間に1回できます。投稿可能になるまでしばらくお待ちください。'
const IS_BANNED_MESSAGE =
  'お客様は現在予想を行うことができません。利用規約をご確認ください。'

class PredictionVoteBox extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    lastPrice: PropTypes.number,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.handleVoteClick = this.handleVoteClick.bind(this)
  }

  handleVoteClick = (trend) => () => {
    const { pair } = this.props

    const code = pair.underscored_code
    chatApi
      .votePrediction(CHAT_ROOM, code, trend)
      .then(() => this.handleVoteSuccess())
      .catch((e) => {
        if (!(e instanceof Error)) {
          this.handleVoteFailed(e)
        }
      })
  }

  handleVoteSuccess() {
    alert('投稿完了です。24時間予想 比率チャートへ反映されました。')
  }

  handleVoteFailed(res) {
    if (res.status == 429) {
      alert(VOTE_FAILED_MESSAGE)
    } else if (res.status == 403) {
      alert(IS_BANNED_MESSAGE)
    }
  }

  render() {
    const { pair, mode } = this.props
    const voteDescription = `ユーザーの売買予想を投稿できます。
あなたの24時間後の予想を投稿しましょう！
予想結果は、チャートの下に表示されます。
`

    return (
      <div className="p-4 rounded-t-md shadow bg-orange-100 relative">
        <div className="flex items-center">
          <TipIcon message={voteDescription} mode={mode} />
          <span>24H 後 {pair.product_name} は</span>
        </div>
        <ul className="mt-1 flex flex-row">
          <li className="basis-1/2 mr-1">
            <a onClick={this.handleVoteClick(TREND_UP)}>
              <div className="w-full bg-buy text-white text-center rounded-lg py-2 shadow hover:shadow-none cursor-pointer hover:opacity-70">
                <i className="icon-arrow-up"></i>上昇
              </div>
            </a>
          </li>
          <li className="basis-1/2">
            <a onClick={this.handleVoteClick(TREND_DOWN)}>
              <div className="w-full bg-sell text-white text-center rounded-lg py-2 shadow hover:shadow-none cursor-pointer hover:opacity-70">
                <i className="icon-arrow-down"></i>下落
              </div>
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

export default (props) => (
  <TickProvider pair={props.pair}>
    <PredictionVoteBox {...props} />
  </TickProvider>
)
