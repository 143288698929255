export const parseTick = (tick) => ({
  code: tick.product_code,
  price: tick.price,
  bid: tick.bid,
})

export const parsePriceColor = (price) => {
  if (price > 0) {
    return 'text-plus'
  } else if (price < 0) {
    return 'text-minus'
  } else {
    return ''
  }
}

export const deviationPct = (currentPrice, lastPrice) => {
  if (lastPrice <= 0) {
    return null
  }

  return ((currentPrice - lastPrice) / lastPrice) * 100
}

export const getPairUnit = (pair) => {
  switch (pair.quoted) {
    case 'btc':
      return 'BTC'
    case 'jpy':
      return '円'
    default:
      return ''
  }
}
