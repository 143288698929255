import PropTypes from 'prop-types'
import React from 'react'

import PairChart from '../PairChart'
import RangeBar from '../../shared/RangeBar'

import { rangeUnitMap, rangeBarPeriods } from '../../../constants/largeChart'

export default class LargeChart extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    defaultRange: PropTypes.string,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)

    const defaultRange = props.defaultRange || rangeBarPeriods[0].value
    this.state = { range: defaultRange }
    this.handleRangeBar = this.handleRangeBar.bind(this)
  }

  handleRangeBar(e) {
    this.setState({ range: e.target.value })
  }

  render() {
    const periods = rangeBarPeriods.map((period) => ({
      ...period,
      default: period.value == this.state.range,
    }))
    const unit = rangeUnitMap[this.state.range].unit
    const limit = this.props.mode == 'pc' ? 1000 : 200

    return (
      <>
        <div className="mb-2">
          <RangeBar periods={periods} onChange={this.handleRangeBar} />
        </div>
        <div className="md:w-[750px] -mx-4 md:mx-0 bg-white shadow p-4">
          <PairChart
            type="candlestick"
            pair={this.props.pair}
            range={this.state.range}
            unit={unit}
            limit={limit}
            mode={this.props.mode}
          />
        </div>
      </>
    )
  }
}
