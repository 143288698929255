import PropTypes from 'prop-types'
import React from 'react'

import LoggedInForm from './LoggedInForm'
import SmsAuthForm from './SmsAuthForm'
import SignInForm from './SignInForm'

const renderFormByUserSmsAuth = ({ smsAuth, loggedInForm, smsAuthForm }) => {
  if (smsAuth) {
    return loggedInForm
  } else {
    return smsAuthForm
  }
}

const ChatForm = ({
  user,
  smsAuthRegistrationPath,
  loginUserUrl,
  showNotice,
  isHide,
}) => {
  const displayClass = isHide ? 'hidden' : ''

  return (
    <div className={`rounded-t-md shadow ${displayClass}`} id="chat_header">
      {user ? (
        renderFormByUserSmsAuth({
          smsAuth: user.sms_auth,
          loggedInForm: <LoggedInForm user={user} />,
          smsAuthForm: (
            <SmsAuthForm smsAuthRegistrationPath={smsAuthRegistrationPath} />
          ),
        })
      ) : (
        <SignInForm showNotice={showNotice} loginUserUrl={loginUserUrl} />
      )}
    </div>
  )
}

ChatForm.propTypes = {
  user: PropTypes.object,
  showNotice: PropTypes.bool,
  smsAuthRegistrationPath: PropTypes.string,
  loginUserUrl: PropTypes.string,
  isHide: PropTypes.bool,
}

export default ChatForm
