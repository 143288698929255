import React from 'react'

const LoadingIndicator = () => {
  return (
    <div className="loading">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  )
}

export default LoadingIndicator
