const localTimeShort = {
  '1H': '1H',
  '24H': '24H',
  '1D': '1日',
  '7D': '7日',
  '30D': '30日',
}
const localTimeFull = {
  '24H': '24時間',
  '7D': '7日間',
  '30D': '30日間',
}
const localQuoted = {
  JPY: '円',
  BTC: 'ビットコイン',
  USDT: 'ドル',
}

const renderLocalized = (unit, localizedForm) => localizedForm[unit]

const updatePageTitle = (timeUnit, quoted) => {
  const timeUnitRegex = /(24時間|7日間|30日間)/
  const quotedRegex = /(円|ビットコイン|ドル)/

  document.title = document.title
    .replace(timeUnitRegex, renderLocalized(timeUnit, localTimeFull))
    .replace(quotedRegex, renderLocalized(quoted, localQuoted))
}

const I18n = {
  localTimeShort,
  localTimeFull,
  localQuoted,
  renderLocalized,
  updatePageTitle,
}

export default I18n
