import PropTypes from 'prop-types'
import React from 'react'

import MessageItem from './MessageItem'
import { parseChatMessageURL } from '../../../../lib/chatHelper'

const MAX_URL_LENGTH = 23

const Message = ({ message }) => {
  const parsedMessage = parseChatMessageURL(message, MAX_URL_LENGTH)

  return (
    <p className="my-1" dangerouslySetInnerHTML={{ __html: parsedMessage }} />
  )
}

const Prediction = ({ code, pairName, trend, lastPrice }) => {
  const direction = trend == 1 ? 'up' : 'down'
  const bgColor = direction == 'up' ? 'bg-buy' : 'bg-sell'
  const priceMessage = ` ${lastPrice}より`

  return (
    <p className="my-1">
      <a href={`/pair/${code}`}>{pairName}</a>
      <i
        className={`p-0.5 rounded-sm text-sm icon-arrow-${direction} text-white ${bgColor}`}
      ></i>
      <span>{priceMessage}</span>
    </p>
  )
}

const MessageList = ({ messages, currentUser }) => (
  <ul className="py-1 px-4 divide-y divide-slate-300 divide-solid">
    {Object.keys(messages)
      .sort((a, b) => b - a)
      .map((id) => {
        const msg = messages[id]

        return (
          <MessageItem key={id} currentUser={currentUser} {...msg}>
            {msg.type == 'message' ? (
              <Message message={msg.message} />
            ) : (
              <Prediction
                code={msg.code}
                pairName={msg.pairName}
                trend={msg.trend}
                lastPrice={msg.lastPrice}
              />
            )}
          </MessageItem>
        )
      })}
  </ul>
)

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      id: PropTypes.number,
      username: PropTypes.string,
      time: PropTypes.string,
      message: PropTypes.string,
      reporters: PropTypes.arrayOf(PropTypes.number),
      code: PropTypes.string,
      pairName: PropTypes.string,
      trend: PropTypes.number,
      type: PropTypes.oneOf(['message', 'prediction']),
      onReportClick: PropTypes.func,
      lastPrice: PropTypes.string,
    })
  ),
  currentUser: PropTypes.object,
}

export default MessageList
