import PropTypes from 'prop-types'
import React from 'react'

import ExchangerRateListFrame from './ExchangerRateListFrame'
import ExchangerRate from './ExchangerRate'

const ExchangerRateList = (props) => (
  <ExchangerRateListFrame title={'取引所ごとのレート'} mode={props.mode}>
    {props.companyTickList.map(({ company, tick }) => (
      <ExchangerRate
        pair={props.pair}
        eventTrackingAction={props.eventTrackingAction}
        company={company}
        tick={tick}
        mode={props.mode}
        bidHighlight={
          props.highlightArbitrage && company == props.bestBidCompany
        }
        askHighlight={
          props.highlightArbitrage && company == props.bestAskCompany
        }
        key={company}
      />
    ))}
  </ExchangerRateListFrame>
)

ExchangerRateList.propTypes = {
  pair: PropTypes.object,
  eventTrackingAction: PropTypes.string,
  companyTickList: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      tick: PropTypes.shape({
        bid: PropTypes.number,
        ask: PropTypes.number,
        volume: PropTypes.number,
      }),
    })
  ),
  bestBidCompany: PropTypes.string,
  bestAskCompany: PropTypes.string,
  highlightArbitrage: PropTypes.bool,
  mode: PropTypes.string,
}

export default ExchangerRateList
