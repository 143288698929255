import PropTypes from 'prop-types'
import React from 'react'

const currentHost = new URL(process.env.DOMAIN).host

const CompanyLink = (props) => {
  const {
    company,
    eventTrackingAction,
    leverageType,
    mode,
    enableOutboundHint,
    klass,
  } = props
  const exchangeUrl = new URL(company.link)
  const outboundEnabled =
    enableOutboundHint && !(exchangeUrl.host.indexOf(currentHost) >= 0)

  const linkText =
    leverageType && mode == 'pc'
      ? `${company.name}(${leverageType})`
      : `${company.name}`

  let linkTarget, linkRef, eventTrackingAttributes

  if (!exchangeUrl.toString().includes('cc.minkabu.jp')) {
    linkTarget = '_blank'
    linkRef = 'nofollow noopener'
  } else if (eventTrackingAction !== undefined) {
    eventTrackingAttributes = {
      'data-vars-event-category': 'Affiliate Attributions',
      'data-vars-event-action': eventTrackingAction,
      'data-vars-event-label': company.code,
    }
  }

  return (
    <a
      target={linkTarget}
      rel={linkRef}
      href={exchangeUrl.toString()}
      class={
        eventTrackingAction == 'Banner/RateProvider' ? 'text-white' : `${klass}`
      }
      {...eventTrackingAttributes}
    >
      {linkText}
      {outboundEnabled ? <i className="icon-new-tab"></i> : ''}
    </a>
  )
}

CompanyLink.propTypes = {
  company: PropTypes.object.isRequired,
  eventTrackingAction: PropTypes.string,
  leverageType: PropTypes.string,
  pairName: PropTypes.string,
  mode: PropTypes.string.isRequired,
  enableOutboundHint: PropTypes.bool,
  klass: PropTypes.string,
}

export default CompanyLink
