import socket from 'socket.io-client'

import { parseChatData } from '../lib/chatHelper'

const io = socket(process.env.SOCKET_URL, {
  transports: ['websocket'],
})

const parseRateData = (data) => {
  const json = JSON.parse(data)

  return {
    time: json.time,
    bid: parseFloat(json.bid),
    ask: parseFloat(json.ask),
    last: parseFloat(json.last),
    volume: parseFloat(json.volume),
  }
}

const parseDayAgoStickData = (data) => {
  const json = JSON.parse(data)

  return {
    closeBid: parseFloat(json.closeBid),
  }
}

const parseMarketCapData = (data) => {
  const json = JSON.parse(data)

  return {
    marketCap: parseFloat(json.market_cap),
  }
}

export const subscribeRateChange = (exchangeCode, pairCode, callback) => {
  io.emit('subscribe', `${exchangeCode.toUpperCase()}-${pairCode}`)
  io.on(`${exchangeCode.toUpperCase()}-${pairCode}-RATE-CHANGE`, (data) => {
    callback(parseRateData(data))
  })
}

export const unsubscribeRateChange = (exchangeCode, pairCode) => {
  io.emit('unsubscribe', `${exchangeCode.toUpperCase()}-${pairCode}`)
}

export const subscribeDayAgoStickChange = (pairCode, callback) => {
  io.emit('subscribe', `${pairCode}-DAY-AGO-STICK`)
  io.on(`${pairCode}-DAY-AGO-STICK-CHANGE`, (data) => {
    callback(parseDayAgoStickData(data))
  })
}

export const unsubscribeDayAgoStickChange = (pairCode) => {
  io.emit('unsubscribe', `${pairCode}-DAY-AGO-STICK`)
}

export const subscribeMarketCapChange = (pairCode, callback) => {
  io.emit('subscribe', `MARKETCAP-${pairCode}`)
  io.on(`MARKETCAP-${pairCode}-RATE-CHANGE`, (data) => {
    callback(parseMarketCapData(data))
  })
}

export const unsubscribeMarketCapChange = (pairCode) => {
  io.emit('unsubscribe', `MARKETCAP-${pairCode}`)
}

export const subscribeChatMessage = (room, callback) => {
  io.emit('subscribe', `chat_${room}`)
  io.on('NEW_MESSAGE', (data) => {
    callback(parseChatData(JSON.parse(data)))
  })
}

export const unsubscribeChatMessage = (room) => {
  io.emit('unsubscribe', `chat_${room}`)
}

export const subscribeChatPrediction = (pairCode, callback) => {
  io.emit('subscribe', `prediction_${pairCode}`)
  io.on('NEW_PREDICTION', callback)
}

export const unsubscribeChatPrediction = (pairCode) => {
  io.emit('unsubscribe', `prediction_${pairCode}`)
}
