import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

import ChatAlert from './ChatAlert'
import MessageList from './MessageList'
import ScrollDetector from '../../shared/ScrollDetector'

import { subscribeChatMessage, unsubscribeChatMessage } from '../../../socket'

import chatApi from '../../../api/chat'
import { setStatePromise } from '../../../lib/helper'

const CHAT_ROOM = 1
const BANNED_MESSAGE =
  '運営事務局にて確認し、利用規約に則り対応を行ってまいります。\n\n通報ありがとうございました。'
const UNLOGGED_IN_MESSAGE = 'アカウント登録もしくはログインしてください.'

export default class ChatBox extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    isHide: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.setStateAsync = setStatePromise(this)

    this.state = {
      messages: [],
      showLoading: false,
      showNoMore: false,
      showNewMessage: false,
    }
    this.page = 1
    this.isReachedBottom = false
    this.isOnTop = true

    this.handleNewMessage = this.handleNewMessage.bind(this)
    this.handleNewMessageClick = this.handleNewMessageClick.bind(this)
    this.handleReportClick = this.handleReportClick.bind(this)
    this.handleTopScroll = this.handleTopScroll.bind(this)
    this.handleBottomScroll = this.handleBottomScroll.bind(this)
    this.handleLeaveTopScroll = this.handleLeaveTopScroll.bind(this)
  }

  componentDidMount() {
    this.loadMessages().then(() => {
      subscribeChatMessage(CHAT_ROOM, this.handleNewMessage)
    })
  }

  handleNewMessage(message) {
    this.addMessage(message)
    this.setState({
      ...this.state,
      showNewMessage: true,
      showLoading: false,
      showNoMore: false,
    })
  }

  async loadMessages() {
    await this.setStateAsync({
      ...this.state,
      showLoading: true,
      showNewMessage: false,
    })

    const data = await chatApi.getMessages(CHAT_ROOM, this.page++)

    await this.addMessages(data)
    this.isReachedBottom = data.length < 50 || this.page >= 50

    await this.setState({ ...this.state, showLoading: false })
  }

  addMessage = (message) => this.addMessages([message])

  async addMessages(newMessages) {
    const messages = Object.assign(
      this.state.messages,
      newMessages.reduce((acc, msg) => {
        acc[msg.id] =
          msg.type == 'message'
            ? {
                ...msg,
                onReportClick: this.handleReportClick(msg.id),
              }
            : msg

        return acc
      }, {})
    )

    await this.setStateAsync({ ...this.state, messages })
  }

  componentWillUnmount() {
    unsubscribeChatMessage(CHAT_ROOM)
  }

  handleReportClick = (messageId) => () => {
    if (confirm('このコメントを通報しますか？')) {
      chatApi
        .reportMessage(messageId)
        .then(() => {
          const { messages } = this.state
          messages[messageId].reported = true
          this.setState({ ...this.state, messages })

          alert(BANNED_MESSAGE)
        })
        .catch((e) => {
          if (!(e instanceof Error)) {
            this.handleReportFailed(e)
          }
        })
    }
  }

  handleReportFailed(res) {
    if (res.status == 401) {
      alert(UNLOGGED_IN_MESSAGE)
    }
  }

  handleTopScroll() {
    this.isOnTop = true
    if (this.state.showNewMessage) {
      this.setState({ ...this.state, showNewMessage: false })
    }
  }

  handleLeaveTopScroll() {
    this.isOnTop = false
  }

  handleBottomScroll() {
    if (this.isReachedBottom) {
      this.setState({
        ...this.state,
        showNoMore: true,
        showLoading: false,
        showNewMessage: false,
      })

      return
    }

    this.loadMessages()
  }

  handleNewMessageClick() {
    this.setState({ ...this.state, showNewMessage: false })
    ReactDOM.findDOMNode(this.refs.chatbox).scrollTop = 0
  }

  renderTopAlert() {
    if (this.state.showLoading) {
      return <ChatAlert key="top" id="top" type="loading" position="top" />
    } else if (this.state.showNewMessage) {
      return (
        <ChatAlert
          type="newMessage"
          position="top"
          timeout={this.isOnTop ? 1000 : null}
          onClick={this.handleNewMessageClick}
        />
      )
    } else {
      return <ChatAlert type="hide" position="top" />
    }
  }

  renderBottomAlert() {
    if (this.state.showNoMore) {
      return <ChatAlert type="noMore" position="bottom" timeout={1000} />
    } else if (this.state.showLoading) {
      return <ChatAlert type="loading" position="bottom" />
    } else {
      return <ChatAlert type="hide" position="bottom" />
    }
  }

  render() {
    const displayClass = this.props.isHide ? 'hidden' : ''

    return (
      <div className={`${displayClass}`}>
        {this.renderTopAlert()}
        <ScrollDetector
          className="h-96 overflow-y-auto"
          ref="chatbox"
          onTop={this.handleTopScroll}
          onLeaveTop={this.handleLeaveTopScroll}
          onBottom={this.handleBottomScroll}
          bottomOffset={10}
        >
          <MessageList
            messages={this.state.messages}
            currentUser={this.props.currentUser}
          />
        </ScrollDetector>
        {this.renderBottomAlert()}
      </div>
    )
  }
}
