import PropTypes from 'prop-types'
import React from 'react'

import CompanyLink from './CompanyLink'

const CompanyNameLink = (props) => {
  const {
    pair,
    eventTrackingAction,
    companyName,
    mode,
    enableOutboundHint,
    klass,
  } = props
  const company = pair.currency_pairs_exchanges.reduce((prev, curr) =>
    curr.exchange.name == companyName ? curr : prev
  )

  return company ? (
    <CompanyLink
      company={company.exchange}
      eventTrackingAction={eventTrackingAction}
      leverageType={pair.leverageType}
      mode={mode}
      enableOutboundHint={enableOutboundHint}
      klass={klass}
    />
  ) : (
    '--'
  )
}

CompanyNameLink.propTypes = {
  pair: PropTypes.object.isRequired,
  eventTrackingAction: PropTypes.string,
  companyName: PropTypes.string,
  showPairName: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  enableOutboundHint: PropTypes.bool,
  klass: PropTypes.string,
}

export default CompanyNameLink
