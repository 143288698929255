import PropTypes from 'prop-types'
import React from 'react'

import dateFormat from 'dateformat'
import { deviationPct } from '../../lib/pairHelper'
import PriceFormatter from '../../lib/formatters/PriceFormatter'
import DeviationFormatter from '../../lib/formatters/DeviationFormatter'
import PercentageFormatter from '../../lib/formatters/PercentageFormatter'

import CompanyLink from '../shared/CompanyLink'
import PairSelector from './PairSelector'
import TickProvider from '../shared/TickProvider'
import TipIcon from '../shared/TipIcon'

const parsePriceBgColor = (price) => {
  if (price > 0) {
    return 'bg-red-600'
  } else if (price < 0) {
    return 'bg-sky-500'
  } else {
    return 'bg-gray-500'
  }
}

const PairRateHeaderSP = (props) => {
  const { pair, pairList, resource, lastPrice, lastBid, dayAgoCloseBid } = props
  const code = pair.underscored_code.toLowerCase()

  const defaultCompany = pair.rate_provider_exchange
  const priceDeviation = lastBid - dayAgoCloseBid
  const bgStatus = parsePriceBgColor(priceDeviation)

  const formattedDeviation = new DeviationFormatter(priceDeviation, {
    format: pair.convert_to,
    round: pair.precision,
  }).result()

  const formattedPrice = new PriceFormatter(lastPrice, {
    round: pair.precision,
    format: pair.convert_to,
    postfix: false,
  }).result()

  const formattedDevPct = new PercentageFormatter(
    deviationPct(lastBid, dayAgoCloseBid)
  ).result()

  const convertUnit = (pair.convert && pair.converted_unit) || ''
  const currentTime = dateFormat(new Date(), 'HH:MM')
  const tipMessage =
    '暗号資産は24時間365日取引されており、始値、終値が取引所で異なります。その為、変動価格と変動比率に関しては、過去24時間の価格で比較しています。'

  return (
    <div className="flex flex-col">
      <div className="flex justify-between pt-2">
        <div className="w-1/2">
          <PairSelector
            pairList={pairList}
            selectedPair={pair}
            resource={resource}
          />
        </div>
        <div className="flex flex-col text-right">
          <div>
            <span id={`${code}_top_uptime`}>{currentTime}</span> 現在値
          </div>
          <div>
            {defaultCompany.link.length == 0 || defaultCompany.link == '-' ? (
              '※当サイト算出値'
            ) : (
              <>
                提供元：
                <CompanyLink
                  pair={pair}
                  company={defaultCompany}
                  eventTrackingAction={'Banner/RateProvider'}
                  mode="phone"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="whitespace-nowrap py-2 text-4xl text-right">
        <strong
          id={`${code}_top_bid`}
          className="font-normal proportional-nums"
        >
          {formattedPrice}
        </strong>
        <small className="font-bold">{pair.converted_unit}</small>
      </div>
      <div className="flex justify-end">
        <div
          className={`${bgStatus} text-sm p-1 rounded border border-solid border-slate-300 flex justify-end items-center`}
        >
          24H変動比：
          <strong className="proportional-nums whitespace-nowrap">
            {`${formattedDeviation} ${convertUnit}(${formattedDevPct})`}
          </strong>
          <span className="relative">
            <TipIcon
              message={tipMessage}
              mode="phone"
              iconColor={'text-white'}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

PairRateHeaderSP.propTypes = {
  pair: PropTypes.object,
  pairList: PropTypes.arrayOf(PropTypes.object),
  resource: PropTypes.string,
  lastPrice: PropTypes.number,
  lastBid: PropTypes.number,
  dayAgoCloseBid: PropTypes.number,
}

export default (props) => (
  <TickProvider pair={props.pair}>
    <PairRateHeaderSP {...props} />
  </TickProvider>
)
