import PropTypes from 'prop-types'
import React from 'react'

import {
  subscribeRateChange,
  unsubscribeRateChange,
  subscribeDayAgoStickChange,
  unsubscribeDayAgoStickChange,
  subscribeMarketCapChange,
  unsubscribeMarketCapChange,
} from '../../socket'

export default class TickProvider extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    onRateUpdate: PropTypes.func,
    onDayAgoStickUpdate: PropTypes.func,
    onMarketCapUpdate: PropTypes.func,
  }

  constructor(props) {
    super(props)

    const lastPrice = parseFloat(props.pair.latest_tick.last) || 0
    const lastBid = parseFloat(props.pair.latest_tick.bid) || 0
    const dayAgoCloseBid = parseFloat(props.pair.day_ago_stick.closeBid) || 0
    const marketCap = parseFloat(props.pair.market_cap.market_cap) || 0

    this.state = { lastPrice, lastBid, dayAgoCloseBid, marketCap }
    this.handleLoad = this.handleLoad.bind(this)
    this.handleRateUpdate = this.handleRateUpdate.bind(this)
    this.handleDayAgoStickUpdate = this.handleDayAgoStickUpdate.bind(this)
    this.handleMarketCapUpdate = this.handleMarketCapUpdate.bind(this)
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      return this.handleLoad()
    }

    window.addEventListener('load', this.handleLoad)
  }

  handleLoad() {
    const { pair } = this.props
    const productCode = pair.product_code
    const ExchangeCode = pair.rate_provider_exchange.code

    subscribeRateChange(ExchangeCode, productCode, this.handleRateUpdate)
    subscribeDayAgoStickChange(productCode, this.handleDayAgoStickUpdate)
    subscribeMarketCapChange(productCode, this.handleMarketCapUpdate)
    this._isMounted = true
  }

  componentWillUnmount() {
    const { pair } = this.props
    const productCode = pair.product_code
    const exchangeCode = pair.rate_provider_exchange.code

    unsubscribeRateChange(exchangeCode, productCode)
    unsubscribeDayAgoStickChange(productCode)
    unsubscribeMarketCapChange(productCode)
    this._isMounted = false

    window.removeEventListener('load', this.handleLoad)
  }

  handleRateUpdate(data) {
    if (!this._isMounted) {
      return
    }
    this.setState({ ...this.state, lastPrice: data.last, lastBid: data.bid })
    if (this.props.onRateUpdate) {
      this.props.onRateUpdate(data)
    }
  }

  handleDayAgoStickUpdate(data) {
    if (!this._isMounted) {
      return
    }
    this.setState({ ...this.state, dayAgoCloseBid: data.closeBid })
    if (this.props.onDayAgoStickUpdate) {
      this.props.onDayAgoStickUpdate(data.closeBid)
    }
  }

  handleMarketCapUpdate(data) {
    if (!this._isMounted) {
      return
    }
    this.setState({ ...this.state, marketCap: data.marketCap })
    if (this.props.onMarketCapUpdate) {
      this.props.onMarketCapUpdate(data.marketCap)
    }
  }

  render() {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, this.state)
    )
  }
}
