import PropTypes from 'prop-types'
import React from 'react'

export default class CoinSwitchButton extends React.Component {
  static propTypes = {
    coins: PropTypes.arrayOf(PropTypes.string),
    selected: PropTypes.number,
    onSwitch: PropTypes.func,
  }

  constructor(props) {
    super(props)

    let selected = 0
    if (this.props.selected == 0 || this.props.selected == 1) {
      selected = this.props.selected
    }
    this.state = { selected }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    if (this.state.selected == e) {
      return
    }
    this.setState({ selected: e })
    this.props.onSwitch(e)
  }

  renderTabs() {
    return this.props.coins.map((coin, idx) => {
      return (
        <li
          className={`inline-block p-2 cursor-pointer shadow hover:bg-blue-500 hover:text-white hover:shadow-none ${
            idx == 0 ? 'rounded-l-lg' : ''
          } ${idx + 1 == this.props.coins.length ? 'rounded-r-lg' : ''} ${
            this.state.selected == idx
              ? 'bg-blue-500 shadow-none text-white'
              : 'bg-slate-50 text-slate-700'
          }`}
          onClick={() => this.handleClick(idx)}
          key={`coinTab-${coin}`}
        >
          {coin.toUpperCase()}
        </li>
      )
    })
  }

  render() {
    return <ul className="text-sm">{this.renderTabs()}</ul>
  }
}
