import PropTypes from 'prop-types'
import React from 'react'

const PromotionNotice = () => (
  <p className="mb-2">
    <i className="icon-clipboard text-sell" />
    IDPASSの設定不要！すでに利用されているSNSと連携登録をするだけで利用できます。
  </p>
)

const SignInForm = ({ showNotice, loginUserUrl }) => (
  <div className="p-4">
    {showNotice && <PromotionNotice />}
    <div className="flex items-center">
      <div className="min-w-[155px]">
        <p className="relative bg-slate-200 p-2 rounded mr-2 text-sm md:text-xs shadow after:w-3 after:h-3 after:absolute after:bg-slate-200 after:-right-1 after:top-[40%] after:rotate-45">
          みんかぶログインとSMS認証をしてチャットを開始
        </p>
      </div>
      <a
        className="w-auto whitespace-nowrap ml-1 p-2 rounded-lg border border-blue-700 border-solid bg-slate-50 shadow font-bold text-center text-blue-700 text-sm hover:opacity-70"
        href={loginUserUrl}
      >
        ログイン
      </a>
      {showNotice && (
        <div className="text-slate-400 text-xs ml-2">
          <p>
            ※みんかぶ（暗号資産）でチャットを開始するにはみんかぶ会員登録を行ってください。
          </p>
        </div>
      )}
    </div>
  </div>
)

SignInForm.propTypes = {
  showNotice: PropTypes.bool,
  loginUserUrl: PropTypes.string,
}

export default SignInForm
