import moment from 'moment-timezone'

const parseChat = (chat) => ({
  id: chat.id,
  userId: chat.user_id,
  username: chat.username,
  time: moment(chat.created_at * 1000)
    .tz('Asia/Tokyo')
    .format('MM/DD HH:mm'),
  type: chat.type,
})

const parseChatMessage = (chat) => ({
  message: chat.message,
  reported: chat.reported,
})

const parseChatPrediction = (prediction) => ({
  code: prediction.code,
  pairName: prediction.pair.product_name,
  trend: prediction.trend,
  lastPrice: prediction.format_last_price + prediction.pair.converted_unit,
})

export const parseChatData = ({ chat }) => {
  const parsedChat = parseChat(chat)
  const parsedData =
    chat.type == 'message'
      ? parseChatMessage(chat)
      : parseChatPrediction(chat.chat_prediction)

  return { ...parsedChat, ...parsedData }
}

const urlRegex =
  /(?:(?:(?:[a-z]+:)?\/\/)|www\.)(?:\S+(?::\S*)?@)?(?:localhost|(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){3}|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[\/?#][^\s"]*)?/gi
const urlSpRegex = /(https?):\/\/([^:^/]*)(:\\d*)?(.*)?/

const zipURL = (url, length) =>
  url.length > length ? `${url.substring(0, length)}...` : url

export const parseChatMessageURL = (message, maxURLLength) =>
  message.replace(urlRegex, (url) => {
    if (!url.match(urlSpRegex)) {
      return url
    }
    if (url.match(urlSpRegex)[2] == window.location.hostname) {
      return `<a href="${url}" rel="nofollow" target="_blank">${zipURL(
        url,
        maxURLLength
      )}</a>`
    } else {
      return `<a href="/outbound?redirect_to=${url}" rel="nofollow" target="_blank">${zipURL(
        url,
        maxURLLength
      )}</a>`
    }
  })

export const getChatMessageLength = (message, maxURLLength) =>
  message.replace(urlRegex, (url) => url.substring(0, maxURLLength)).length
