import PropTypes from 'prop-types'
import React from 'react'

import AlertMessage from './AlertMessage'

const MESSAGE_MAP = {
  loading: 'データ読み込み中です…',
  noMore: 'これ以上スクロールできません。',
  newMessage: '新しいメッセージ',
}

export default class ChatAlert extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['loading', 'noMore', 'newMessage', 'hide']),
    position: PropTypes.oneOf(['top', 'bottom']),
    timeout: PropTypes.number,
    onClick: PropTypes.func,
    onHide: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = { hide: true }
    this.handleClick = this.handleClick.bind(this)
  }

  init(type) {
    this.setState({ hide: type == 'hide' })

    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timeout !== this.props.timeout) {
      this.init(this.props.type)

      if (this.props.timeout) {
        this.hideTimeout = setTimeout(() => this.hide(), this.props.timeout)
      }
    }
  }

  handleClick() {
    this.hide()
    this.props.onClick && this.props.onClick()
  }

  hide() {
    this.setState({ hide: true })
    this.props.onHide && this.props.onHide()
  }

  render() {
    const { position, type } = this.props
    const { hide } = this.state

    const message = MESSAGE_MAP[type]
    const onClick = type === 'newMessage' ? this.handleClick : null

    return (
      <AlertMessage
        message={message}
        onClick={onClick}
        position={position}
        hide={hide}
      />
    )
  }
}
