import PropTypes from 'prop-types'
import React from 'react'

import MiniRateChartHead from './MiniRateChartHead'
import PairChart from '../PairChart'

const MiniRateChart = (props) => {
  const { pair, mode } = props

  return (
    <div>
      <MiniRateChartHead pair={pair} mode={mode} />
      <div className="h-28">
        <PairChart type="area" {...props} />
      </div>
    </div>
  )
}

MiniRateChart.propTypes = {
  pair: PropTypes.object,
  range: PropTypes.string,
  unit: PropTypes.number,
  limit: PropTypes.number,
  mode: PropTypes.string,
}

export default (props) => <MiniRateChart {...props} />
