import api from './api'
import { parseChatData } from '../lib/chatHelper'

export default {
  getMessages: (room, page) =>
    api
      .fetch('/api/chat_message.json', { room, page })
      .then((array) => array.map(parseChatData)),
  sendMessage: (room, message) =>
    api.post('/api/chat_message', { room, message }),
  reportMessage: (message) =>
    api.post(`/api/chat_message/${message}/chat_report`),
  votePrediction: (room, code, trend) =>
    api.post('/api/chat_prediction', { room, code, trend }),
  updateNickname: (id, nickname) =>
    api.post(`/api/users/${id}`, { id, nickname }),
}
