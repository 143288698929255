import React from 'react'
import CoinIcon from '../shared/CoinIcon'
import I18n from '../CurrencyRankingTable/shared/i18n'
import Api from '../../api/api'
import Util from '../CurrencyRankingTable/shared/util'
import PriceFormatter from '../../lib/formatters/PriceFormatter'

export default class SidebarPairsDeviation extends React.Component {
  constructor(props) {
    super(props)
    let products = []
    Api.fetch('/api/ranking/deviation/quoted/JPY/interval/24H').then(
      (response) => {
        products = this.filterByDirection(response.items)
      }
    )
    this.state = {
      products,
      sortKey: 'dev_pct_24h',
      sortOrder: true,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.scheduledFetcher = setInterval(() => this.fetchData(), 10000)
  }

  componentWillUnmount() {
    clearInterval(this.scheduledFetcher)
  }

  humanTimeUnit() {
    return I18n.renderLocalized('24H', I18n.localTimeShort)
  }

  filterByDirection(pairs) {
    return pairs.filter((pair) => {
      const dev = pair.dev_24h

      return dev >= 0 && dev !== null
    })
  }

  fetchData() {
    if (document.hasFocus()) {
      Api.fetch('/api/ranking/deviation/quoted/JPY/interval/24H').then(
        (response) => {
          this.setState({
            ...this.state,
            products: this.filterByDirection(response.items),
            sortKey: 'dev_pct_24h',
            sortOrder: true,
            isLoading: false,
          })
        }
      )
    }
  }

  rankByDeviation() {
    return Util.sortedProducts({
      products: this.state.products,
      sortKey: this.state.sortKey,
      sortOrder: this.props.sortOrder,
    }).map((product, index) => {
      if (index >= 5) {
        return
      }

      const formattedPrice = new PriceFormatter(product.ltp, {
        round: product.precision,
        format: product.convert_to,
      }).result()

      const deviation = Util.renderDeviation({
        product,
        dev: 'dev_24h',
        devPct: 'dev_pct_24h',
      })

      return (
        <tr className="h-8" key={product.underscored_code}>
          <td className="text-blue-700">
            <a href={`/pair/${product.underscored_code}`} className="flex">
              <CoinIcon coinName={product.base} />
              {product.base_currency_name}
            </a>
          </td>
          <td className="proportional-nums p-2 text-right">
            <div className="whitespace-nowrap">{formattedPrice}</div>
            <div
              className={`${
                product.dev_24h > 0
                  ? 'text-plus whitespace-nowrap'
                  : 'text-minus whitespace-nowrap'
              }`}
            >
              {deviation.inNum}
            </div>
          </td>
          <td
            className={`proportional-nums p-2 text-right ${
              product.dev_24h > 0 ? 'text-plus' : 'text-minus'
            }`}
          >
            <span>{deviation.inRate}</span>
          </td>
        </tr>
      )
    })
  }

  renderRanked() {
    const colSpan = 3
    const rankedResult = this.rankByDeviation()
    if (this.state.isLoading) {
      return Util.loadingPlaceholder({ colSpan })
    }
    if (rankedResult.length === 0) {
      return Util.emptyPlaceholder({
        colSpan,
        pageName: '値上がり率',
        timeUnit: this.humanTimeUnit(),
      })
    }

    return rankedResult
  }

  render() {
    return (
      <table className="w-full text-sm">
        <thead>
          <tr className="text-center bg-slate-200 whitespace-nowrap">
            <th className="p-2">通貨名</th>
            <th className="p-2">価格</th>
            <th className="p-2">24H変動比</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-slate-300 divide-solid">
          {this.renderRanked()}
        </tbody>
      </table>
    )
  }
}
