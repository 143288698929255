import baseFormatter from './base'

export default class PercentageFormatter {
  constructor(number, options = {}) {
    this.number = parseFloat(number)
    this.round = options.round || options.round == 0 ? options.round : 2
    this.postfix = options.postfix
  }

  result() {
    if (!this.number) {
      return '--'
    }

    return this.priceString()
  }

  priceString() {
    let pct = this.formattedPrice()

    if (pct > 0) {
      pct = `+${pct}`
    }

    if (this.postfix == false) {
      return pct
    }

    return `${pct}%`
  }

  formattedPrice() {
    const formatter = baseFormatter({ round: this.round })

    return formatter(this.number)
  }
}
