export const setStatePromise = (that) => (newState) =>
  new Promise((resolve) => {
    that.setState(newState, () => {
      resolve()
    })
  })

export const unique = (arr, key) =>
  arr.reduce(
    (acc, e) => (acc.find((x) => x[key] == e[key]) ? acc : acc.concat(e)),
    []
  )
