import PropTypes from 'prop-types'
import React from 'react'

import PredictionVoteBox from '../prediction/PredictionVoteBox'
import ChatForm from './ChatForm'
import ChatBox from './ChatBox'

export default class ChatFrame extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    showPrediction: PropTypes.bool,
    showNotice: PropTypes.bool,
    showTitle: PropTypes.bool,
    currentUser: PropTypes.object,
    loginUserUrl: PropTypes.string,
    smsAuthRegistrationPath: PropTypes.string,
    mode: PropTypes.oneOf(['pc', 'phone']),
  }

  constructor(props) {
    super(props)

    this.state = { isChatBoxHide: false }
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState({ isChatBoxHide: !this.state.isChatBoxHide })
  }

  render() {
    const {
      pair = null,
      showPrediction = false,
      showNotice = false,
      showTitle = false,
      currentUser = null,
      loginUserUrl = '',
      smsAuthRegistrationPath = '',
      mode = 'pc',
    } = this.props
    const toggleButtonClass = this.state.isChatBoxHide
      ? 'icon-chevron-thin-down'
      : 'icon-chevron-thin-up'

    return (
      <div className="mb-8">
        {showTitle && (
          <div className="flex justify-between">
            <h3 className="mb-4">みんかぶ（暗号資産）チャット</h3>
            <a
              href="#"
              aria-label="チャットの開閉"
              onClick={this.handleToggleClick}
            >
              <i
                className={`p-2 border border-solid border-slate-300 rounded text-xs text-slate-500 ${toggleButtonClass}`}
              />
            </a>
          </div>
        )}
        <div className="bg-white rounded-md shadow">
          {showPrediction && (
            <PredictionVoteBox
              pair={pair}
              onToggleClick={this.handleToggleClick}
              isHide={this.state.isChatBoxHide}
              mode={mode}
            />
          )}
          <ChatForm
            showNotice={showNotice}
            isHide={this.state.isChatBoxHide}
            user={currentUser}
            loginUserUrl={loginUserUrl}
            smsAuthRegistrationPath={smsAuthRegistrationPath}
          />
          <ChatBox
            isHide={this.state.isChatBoxHide}
            currentUser={currentUser}
          />
        </div>
      </div>
    )
  }
}
