import React from 'react'
import Util from '../CurrencyRankingTable/shared/util'
import PriceFormatter from '../../lib/formatters/PriceFormatter'

export default function PairStatusSP(props) {
  const pair = props.pair
  const args = { mode: 'MOBILE', product: pair, quoted: pair.quoted }
  const formatOptions = {
    round: pair.precision,
    format: pair.convert_to,
    postfix: false,
  }
  const intervals = ['24H', '7D', '30D']
  const humanInterval = {
    '24H': '24H',
    '7D': '7日',
    '30D': '30日',
  }
  const renderHighLow = (interval) => {
    const formattedHigh = new PriceFormatter(
      pair[`high_${interval.toLowerCase()}`],
      formatOptions
    ).result()
    const formattedLow = new PriceFormatter(
      pair[`low_${interval.toLowerCase()}`],
      formatOptions
    ).result()

    return (
      <tr key={`HIGHLOW-${interval}`}>
        <td className="p-2 font-bold">
          {humanInterval[interval]}最高値/最安値
        </td>
        <td className="p-2 text-right">
          <span className="text-plus">{formattedHigh}</span>
          {' / '}
          <span className="text-minus">{formattedLow}</span>
          {pair.converted_unit}
        </td>
      </tr>
    )
  }
  const renderVolume = (interval) => {
    return (
      <tr key={`VOL-${interval}`}>
        <td className="p-2 text-blue-700">
          <a
            href={`/ranking/trading_volume/quoted/${pair.quoted.toUpperCase()}/interval/${interval}`}
            className="font-bold"
          >
            {humanInterval[interval]}売買代金
          </a>
        </td>
        <td className="p-2 text-right">
          {Util.formatTradingVolume({
            ...args,
            volume: `vol_${interval.toLowerCase()}`,
          })}
        </td>
      </tr>
    )
  }

  return (
    <div className="mb-4">
      <table className="w-full text-sm">
        <tbody class="divide-y divide-slate-300 divide-solid">
          <tr>
            <td className="p-2 font-bold">時価総額ランク</td>
            <td className="p-2 text-right">{pair.cmc_rank}位</td>
          </tr>
          {intervals.map((interval) => renderHighLow(interval))}
          {intervals.map((interval) => renderVolume(interval))}
          <tr></tr>
        </tbody>
      </table>
      <div className="flex justify-end pt-2 pr-2">
        <a
          href={`/ranking/deviation/up/quoted/${pair.quoted.toUpperCase()}`}
          className="flex items-center text-blue-700"
        >
          <i className="icon-chevron-right2 align-middle"></i>
          暗号資産各指標ランキング一覧
        </a>
      </div>
    </div>
  )
}
