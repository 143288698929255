import PropTypes from 'prop-types'
import React from 'react'

import RangeBar from '../shared/RangeBar'
import MiniRateChart from '../chart/MiniRateChart'
import { rangeUnitMap, rangeBarPeriods } from '../../constants/miniChart'

export default class MiniRateChartTable extends React.Component {
  static propTypes = {
    pairs: PropTypes.array,
    defaultRange: PropTypes.string,
    hintType: PropTypes.string,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)

    const defaultRange = props.defaultRange || rangeBarPeriods[0].value
    this.state = { range: defaultRange }
    this.handleRangeBar = this.handleRangeBar.bind(this)
  }

  handleRangeBar(e) {
    this.setState({ range: e.target.value })
  }

  renderCharts() {
    const rangeUnitPair = rangeUnitMap[this.state.range]

    return this.props.pairs.map((pair) => (
      <div key={pair.product_code}>
        <div className="relative overflow-hidden bg-white rounded-lg shadow">
          <MiniRateChart
            pair={pair}
            range={rangeUnitPair.range}
            unit={rangeUnitPair.unit}
            limit={rangeUnitPair.amount}
            mode={this.props.mode}
            key={pair.product_code}
          />
        </div>
      </div>
    ))
  }

  render() {
    const periods = rangeBarPeriods.map((period) => ({
      ...period,
      default: period.value == this.state.range,
    }))

    return (
      <div>
        <div className="text-right mb-2">
          <RangeBar periods={periods} onChange={this.handleRangeBar} />
        </div>
        <div
          id="jpy_chart_box"
          className="flex grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 mb-2"
        >
          {this.renderCharts()}
        </div>
      </div>
    )
  }
}
