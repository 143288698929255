import React from 'react'
import CoinIcon from '../../shared/CoinIcon'
import I18n from '../shared/i18n'
import PairHeading from '../../shared/pairs/PairHeading'
import Util from '../shared/util'
import PriceFormatter from '../../../lib/formatters/PriceFormatter'

export default class HighLowRanking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...nextProps,
    }
  }

  humanTimeUnit() {
    return I18n.renderLocalized(this.props.timeUnit, I18n.localTimeShort)
  }

  rankByExtremePct() {
    const sortedProducts = Util.sortedProducts({
      products: this.state.products,
      sortKey: this.state.sortKey,
      sortOrder: this.props.sortOrder,
    })

    return sortedProducts.map((product, index) => {
      const timeUnit = this.props.timeUnit.toLowerCase()
      const recentTimeUnit = (
        this.props.timeUnit === '24H' ? '1H' : '1D'
      ).toLowerCase()
      const extreme = this.props.extreme.toLowerCase()
      const formatOptions = {
        round: product.precision,
        format: product.convert_to,
      }

      const formattedLtp = new PriceFormatter(
        product.ltp,
        formatOptions
      ).result()

      const formattedRecentExtreme = new PriceFormatter(
        product[`${extreme}_${recentTimeUnit}`],
        formatOptions
      ).result()

      const formattedHistoryExtreme = new PriceFormatter(
        product[`${extreme}_${timeUnit}`],
        formatOptions
      ).result()

      const deviation = Util.renderDeviation({
        product,
        dev: `dev_${extreme}_${timeUnit}`,
        devPct: `dev_pct_${extreme}_${timeUnit}`,
      })

      return (
        <tr key={product.underscored_code}>
          <td className="italic text-center whitespace-nowrap">{index + 1}</td>
          <td className="text-center whitespace-nowrap">
            <a href={`/pair/${product.underscored_code}`}>
              <CoinIcon coinName={product.base} />
            </a>
          </td>
          <td className="text-blue-700">
            <PairHeading mode={this.props.mode} product={product} />
          </td>
          <td className="p-1 proportional-nums whitespace-nowrap text-right">
            {formattedLtp}
          </td>
          <td className="p-1 proportional-nums whitespace-nowrap text-right">
            {formattedRecentExtreme}
          </td>
          <td className="p-1 proportional-nums whitespace-nowrap text-right">
            {formattedHistoryExtreme}
          </td>
          <td
            className={`p-1 proportional-nums text-right whitespace-nowrap ${
              product[`dev_${extreme}_${timeUnit}`] > 0
                ? 'text-plus'
                : 'text-minus'
            }`}
          >
            <p>{deviation.inNum}</p>
            <p>({deviation.inRate})</p>
          </td>
        </tr>
      )
    })
  }

  renderRanked() {
    const colSpan = 7
    const rankedResult = this.rankByExtremePct()
    if (this.state.isLoading) {
      return Util.loadingPlaceholder({ colSpan })
    }
    if (rankedResult.length === 0) {
      return Util.emptyPlaceholder({
        colSpan,
        pageName: this.props.pageName,
        timeUnit: this.humanTimeUnit(),
      })
    }

    return rankedResult
  }

  render() {
    const humanExtreme = this.props.extreme === 'HIGH' ? '高' : '安'

    return (
      <div className="pl-4 py-4 md:pr-4 md:rounded-lg shadow bg-white -mx-4 md:mx-0">
        <div class="overflow-x-scroll">
          <table className="w-full text-sm md:text-base mr-4 md:mr-0">
            <thead>
              <tr className="text-center bg-slate-200">
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  <span className="hidden md:block">順位</span>
                </th>
                <th
                  className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap"
                  colSpan="2"
                >
                  通貨名
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  現在値
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  {I18n.renderLocalized(
                    this.props.timeUnit === '24H' ? '1H' : '1D',
                    I18n.localTimeShort
                  )}
                  {humanExtreme}値
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  {this.humanTimeUnit()}最{humanExtreme}値
                </th>
                <th className="px-1 py-2 md:px-2 border border-solid border-white whitespace-nowrap">
                  {this.humanTimeUnit()}
                  {humanExtreme}値変動比
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-slate-300 divide-solid">
              {this.renderRanked()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
