import PropTypes from 'prop-types'
import React from 'react'

import coin from '../../constants/coin'

export default class CoinIcon extends React.Component {
  static propTypes = {
    coinName: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { coinIcon: { name: '', path: 0 } }
  }

  updateCoinIcon(coinName) {
    const currentCoin = coin[coinName.toLowerCase()]
    if (currentCoin) {
      this.setState({
        coinIcon: { name: currentCoin.icon, path: currentCoin.path },
      })
    }
  }

  componentDidMount() {
    this.updateCoinIcon(this.props.coinName)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.coinName !== prevProps.coinName) {
      this.updateCoinIcon(this.props.coinName)
    }
  }

  renderIconPath(paths) {
    const pathList = []

    for (let i = 1; i <= paths; i++) {
      pathList.push(<span className={`path${i}`} key={i}></span>)
    }

    return pathList
  }

  render() {
    const { coinIcon } = this.state

    return (
      <i className={`currency ${coinIcon.name}`}>
        {this.renderIconPath(coinIcon.path)}
      </i>
    )
  }
}
