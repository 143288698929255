import React from 'react'
import moment from 'moment-timezone'
import Api from '../../../api/api'
import I18n from '../shared/i18n'
import Head from '../shared/head'
import TabOption from '../shared/tabOption'
import HighLowRanking from './HighLowRanking'

export default class HighLowRankingTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuoted: props.quoted,
      selectedTimeUnit: props.timeUnit,
      selectedPairs: props.selectedPairs,
      updatedAt: moment(new Date(props.updatedAt))
        .tz('Asia/Tokyo')
        .format('YYYY/MM/DD HH:mm'),
      isLoading: false,
    }

    this.updateTab = this.updateTab.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    this.scheduledFetcher = setInterval(() => this.fetchData(), 10000)

    window.onpopstate = (event) => {
      if (window.history.state === undefined) {
        return
      }

      if (!(event.state && event.state.quoted && event.state.timeUnit)) {
        return window.location.reload()
      }

      this.updateTab({
        quoted: event.state.quoted,
        timeUnit: event.state.timeUnit,
        pushState: false,
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.scheduledFetcher)
  }

  filterByExtreme(pairs) {
    const extreme = this.props.extreme.toLowerCase()
    const timeUnit = this.state.selectedTimeUnit.toLowerCase()

    return pairs.filter((pair) => {
      const devPct = pair[`dev_pct_${extreme}_${timeUnit}`]

      if (extreme === 'high') {
        return devPct >= 0 && devPct !== null
      } else {
        return devPct <= 0 && devPct !== null
      }
    })
  }

  fetchData() {
    if (document.hasFocus()) {
      Api.fetch(
        `/api/ranking/${this.props.extreme.toLowerCase()}/quoted/${
          this.state.selectedQuoted
        }/interval/${this.state.selectedTimeUnit}`
      ).then((response) => {
        this.setState({
          ...this.state,
          selectedPairs: response.items,
          updatedAt: moment(new Date(response.updated_at))
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm'),
          isLoading: false,
        })
      })
    }
  }

  updateTab({
    quoted = this.state.selectedQuoted,
    timeUnit = this.state.selectedTimeUnit,
    pushState = true,
  }) {
    this.setState(
      {
        ...this.state,
        selectedQuoted: quoted,
        selectedTimeUnit: timeUnit || this.state.selectedTimeUnit,
        isLoading: true,
      },
      this.fetchData
    )

    if (!pushState) {
      return
    }

    history.pushState(
      { quoted, timeUnit },
      '',
      `/ranking/${this.props.extreme.toLowerCase()}/quoted/${quoted}/interval/${timeUnit}`
    )
    I18n.updatePageTitle(timeUnit, quoted)
  }

  render() {
    const timeUnit = ['24H', '7D', '30D']
    const humanExtreme = this.props.extreme === 'HIGH' ? '高' : '安'
    const humanTimeRange = {
      '24H': '24~25時',
      '7D': '7~8日',
      '30D': '30~31日',
    }

    return (
      <>
        <Head
          pageName={this.props.pageName}
          quoted={this.state.selectedQuoted}
          timeUnit={this.state.selectedTimeUnit}
          currentPath={this.props.currentPath}
          btcIconPath={this.props.btcIconPath}
          pairIndexPath={this.props.pairIndexPath}
        />
        <section id="pairs-ranking" className="mb-12 md:mb-16">
          <div className="text-xs md:text-base md:flex md:justify-between mb-2">
            <p className="text-slate-500">更新日時 {this.state.updatedAt}</p>
            <div className="md:flex mt-3 md:mt-0">
              <ul className="flex justify-end pb-4 md:pb-0 md:mr-7">
                <li className="text-slate-500 mr-1 md:mr-1.5">通貨ペア</li>
                {this.props.pairLists.map(({ quoted }) => {
                  return (
                    <TabOption
                      checked={this.state.selectedQuoted == quoted}
                      onClick={() => this.updateTab({ quoted })}
                      name="quoted"
                      key={quoted}
                      text={quoted}
                    />
                  )
                })}
              </ul>
              <ul className="flex justify-end">
                <li className="text-slate-500 mr-1 md:mr-1.5">期間</li>
                {timeUnit.map((unit) => {
                  return (
                    <TabOption
                      checked={this.state.selectedTimeUnit == unit}
                      onClick={() => {
                        this.updateTab({ timeUnit: unit })
                      }}
                      name="timeUnit"
                      key={unit}
                      text={unit}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
          <HighLowRanking
            products={this.filterByExtreme(this.state.selectedPairs)}
            pageName={this.props.pageName}
            quoted={this.state.selectedQuoted}
            timeUnit={this.state.selectedTimeUnit}
            extreme={this.props.extreme}
            sortKey={`dev_pct_${this.props.extreme.toLowerCase()}_${this.state.selectedTimeUnit.toLowerCase()}`.toString()}
            sortOrder={this.props.extreme === 'HIGH'}
            isLoading={this.state.isLoading}
            mode={this.props.mode}
          />
          <p className="my-2 text-xs md:text-sm text-slate-500">
            {`※ ${I18n.localTimeShort[this.state.selectedTimeUnit]}` +
              `最${humanExtreme}値は、計算の都合上過去` +
              `${humanTimeRange[this.state.selectedTimeUnit]}間の期間の` +
              `最${humanExtreme}値。`}
          </p>
        </section>
      </>
    )
  }
}
