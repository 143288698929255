import React from 'react'
import Util from '../CurrencyRankingTable/shared/util'
import PriceFormatter from '../../lib/formatters/PriceFormatter'

export default function PairStatusPC(props) {
  const pair = props.pair
  const args = { mode: 'DESKTOP', product: pair, quoted: pair.quoted }
  const formatOptions = {
    round: pair.precision,
    format: pair.convert_to,
    postfix: false,
  }
  const intervals = ['24H', '7D', '30D']
  const humanInterval = {
    '24H': '24H',
    '7D': '7日',
    '30D': '30日',
  }
  const renderHighLow = (interval) => {
    const formattedHigh = new PriceFormatter(
      pair[`high_${interval.toLowerCase()}`],
      formatOptions
    ).result()
    const formattedLow = new PriceFormatter(
      pair[`low_${interval.toLowerCase()}`],
      formatOptions
    ).result()

    return (
      <React.Fragment key={`HIGHLOW-${interval}`}>
        <tr className="h-8">
          <th className="p-1 w-1/2 bg-slate-200 border border-solid border-white">
            {humanInterval[interval]}最高値
          </th>
          <th className="p-1 bg-slate-200 border border-solid border-white">
            {humanInterval[interval]}最安値
          </th>
        </tr>
        <tr className="h-8">
          <td className="p-1 text-right">
            <span className="text-plus">{formattedHigh}</span>
            {pair.converted_unit}
          </td>
          <td className="p-1 text-right">
            <span className="text-minus">{formattedLow}</span>
            {pair.converted_unit}
          </td>
        </tr>
      </React.Fragment>
    )
  }
  const renderVolume = (interval) => {
    return (
      <tr className="h-8" key={`VOL-${interval}`}>
        <th className="p-1 bg-slate-200 border border-solid border-white">
          <a
            href={`/ranking/trading_volume/quoted/${pair.quoted.toUpperCase()}/interval/${interval}`}
          >
            {humanInterval[interval]}売買代金
          </a>
        </th>
        <td className="p-1 text-right">
          {Util.formatTradingVolume({
            ...args,
            volume: `vol_${interval.toLowerCase()}`,
          })}
        </td>
      </tr>
    )
  }

  return (
    <div className="bg-white rounded-md shadow p-4 md:p-2">
      <table className="w-full mb-2 text-center">
        <tbody>
          {intervals.map((interval) => renderHighLow(interval))}
          <tr className="h-8">
            <th className="p-1 bg-slate-200 border border-solid border-white">
              時価総額ランク
            </th>
            <td className="p-1 text-right">{pair.cmc_rank}位</td>
          </tr>
          {intervals.map((interval) => renderVolume(interval))}
        </tbody>
      </table>
      <div className="pt-2 border-t border-dashed border-slate-300">
        <a href={`/ranking/deviation/up/quoted/${pair.quoted.toUpperCase()}`}>
          <div className="p-2 w-full rounded-lg border border-blue-700 border-solid bg-slate-50 shadow font-bold text-center text-blue-700 hover:opacity-70">
            暗号資産各指標ランキング一覧<i className="icon-chevron-right2"></i>
          </div>
        </a>
      </div>
    </div>
  )
}
