import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

export default class ScrollDetector extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    topOffset: PropTypes.number,
    bottomOffset: PropTypes.number,
    onTop: PropTypes.func,
    onLeaveTop: PropTypes.func,
    onBottom: PropTypes.func,
    onLeaveBottom: PropTypes.func,
    children: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.scrollState = 'top'
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0
  }

  handleScroll(e) {
    const { onTop, onLeaveTop, onBottom, onLeaveBottom } = this.props

    if (this.isTopScroll(e.target)) {
      this.scrollState = 'top'
      onTop && onTop()
    } else if (this.isBottomScroll(e.target)) {
      this.scrollState = 'bottom'
      onBottom && onBottom()
    } else if (this.scrollState == 'top') {
      this.scrollState = 'middle'
      onLeaveTop && onLeaveTop()
    } else if (this.scrollState == 'bottom') {
      this.scrollState = 'middle'
      onLeaveBottom && onLeaveBottom()
    }
  }

  isTopScroll(e) {
    const offset = this.props.topOffset || 0

    return e.scrollTop - offset <= 0
  }

  isBottomScroll(e) {
    const offset = this.props.bottomOffset || 0

    return e.scrollHeight - e.scrollTop - offset <= e.clientHeight
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onScroll={this.handleScroll}
      >
        {this.props.children}
      </div>
    )
  }
}
