import PropTypes from 'prop-types'
import React from 'react'

import PairStatusSP from '../PairStatus/sp'
import ExchangerRateList from './ExchangerRateList'
import BestArbitrageRow from './BestArbitrageRow'
import RelatedPairRateList from './RelatedPairRateList'

import { subscribeRateChange, unsubscribeRateChange } from '../../socket'

export default class PairRateFrame extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    pairList: PropTypes.arrayOf(PropTypes.object),
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      companyTickList: this.generateDefaultCompanyTickList(),
      bestBidCompany: null,
      bestAskCompany: null,
      diffPrice: null,
    }
    this.handleLoad = this.handleLoad.bind(this)
    this.handleRateUpdate = this.handleRateUpdate.bind(this)
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      return this.handleLoad()
    }

    window.addEventListener('load', this.handleLoad)
  }

  handleLoad() {
    const productCode = this.props.pair.product_code
    const companyList = this.props.pair.currency_pairs_exchanges

    this.companyTickMap = new Map()

    companyList.forEach((company) => {
      const exchangeName = company.exchange.name
      const exchangeCode = company.exchange.code
      const { bid, ask, volume } = company.latest_tick

      this.companyTickMap.set(exchangeName, {
        bid: null,
        ask: null,
        volume: null,
      })
      this.handleRateUpdate(exchangeName)({
        bid: parseFloat(bid),
        ask: parseFloat(ask),
        volume: parseFloat(volume),
      })

      subscribeRateChange(
        exchangeCode,
        productCode,
        this.handleRateUpdate(exchangeName)
      )
    })
  }

  componentWillUnmount() {
    const code = this.props.pair.product_code
    const companyList = this.props.pair.currency_pairs_exchanges

    companyList.forEach((company) => {
      const exchangeCode = company.exchange.code

      unsubscribeRateChange(exchangeCode, code)
    })

    window.removeEventListener('load', this.handleLoad)
  }

  sortProductsByVolume() {
    const keys = Array.from(this.companyTickMap.keys())

    return keys
      .map((com) => ({
        company: com,
        tick: this.companyTickMap.get(com),
      }))
      .sort((a, b) => (a.tick.volume < b.tick.volume ? 1 : -1))
  }

  handleRateUpdate(company) {
    return (data) => {
      this.companyTickMap.set(company, {
        bid: data.bid,
        ask: data.ask,
        volume: data.volume,
      })
      const keys = Array.from(this.companyTickMap.keys())
      const companyTickList = this.sortProductsByVolume()

      const bestBidCompany = keys.reduce((prev, curr) => {
        const prevBid = prev && this.companyTickMap.get(prev).bid
        const currBid = curr && this.companyTickMap.get(curr).bid

        return prevBid > currBid ? prev : curr
      }, company)

      const bestAskCompany = keys.reduce((prev, curr) => {
        const prevAsk = prev && this.companyTickMap.get(prev).ask
        const currAsk = curr && this.companyTickMap.get(curr).ask
        if (!currAsk) {
          return prev
        }

        return prevAsk < currAsk ? prev : curr
      }, company)

      const diffPrice =
        this.companyTickMap.get(bestBidCompany).bid -
        this.companyTickMap.get(bestAskCompany).ask

      this.setState({
        companyTickList,
        bestBidCompany,
        bestAskCompany,
        diffPrice,
      })
    }
  }

  generateDefaultCompanyTickList() {
    const companyList = this.props.pair.currency_pairs_exchanges

    return companyList
      .map(({ exchange, latest_tick: tick }) => ({
        company: exchange.name,
        tick: {
          bid: parseFloat(tick.bid),
          ask: parseFloat(tick.ask),
          volume: parseFloat(tick.volume),
        },
      }))
      .sort((a, b) => (a.tick.volume < b.tick.volume ? 1 : -1))
  }

  render() {
    const { pair, mode } = this.props
    const showArbitrage = this.state.companyTickList.length > 3

    return (
      <>
        {mode === 'pc' ? '' : <PairStatusSP pair={pair} />}
        <ExchangerRateList
          pair={pair}
          eventTrackingAction={'Table/TickerSpotted'}
          companyTickList={this.state.companyTickList}
          bestBidCompany={this.state.bestBidCompany}
          bestAskCompany={this.state.bestAskCompany}
          highlightArbitrage={showArbitrage}
          mode={mode}
        />
        {showArbitrage ? (
          <BestArbitrageRow
            pair={pair}
            askCompany={this.state.bestAskCompany}
            bidCompany={this.state.bestBidCompany}
            diffPrice={this.state.diffPrice}
            mode={mode}
          />
        ) : null}
        {pair.related.length > 0 ? (
          <RelatedPairRateList
            pair={pair}
            eventTrackingAction={'Table/TickerFXLeveraged'}
            mode={mode}
          />
        ) : null}
      </>
    )
  }
}
