import React from 'react'
import CoinIcon from '../shared/CoinIcon'
import ColoredPrice from '../shared/ColoredPrice'
import PriceFormatter from '../../lib/formatters/PriceFormatter'
import DeviationFormatter from '../../lib/formatters/DeviationFormatter'
import TickProvider from '../shared/TickProvider'
import { parsePriceColor, deviationPct } from '../../lib/pairHelper'

const SidebarPairsTable = (props) => {
  const { precision, base, name, lastPrice, lastBid, dayAgoCloseBid } = props
  const convertTo = props.convert_to
  const underscoredCode = props.underscored_code
  const bgStatus = parsePriceColor(deviationPct(lastBid, dayAgoCloseBid))
  const priceDeviation = lastBid - dayAgoCloseBid

  const formattedPrice = new PriceFormatter(lastPrice, {
    round: precision,
    format: convertTo,
    postfix: false,
  }).result()

  const formattedDeviation = new DeviationFormatter(priceDeviation, {
    format: convertTo,
    round: precision,
  }).result()

  return (
    <tr className="h-8">
      <td className="py-2 text-blue-700 hover:opacity-70">
        <a href={`/pair/${underscoredCode}`} className="flex">
          <CoinIcon coinName={base} />
          {name}
        </a>
      </td>
      <ColoredPrice
        price={parseFloat(lastPrice)}
        formattedPrice={formattedPrice}
      />
      <td className={`proportional-nums p-2 text-right ${bgStatus}`}>
        {formattedDeviation}
      </td>
    </tr>
  )
}

export default (props) => (
  <table className="w-full text-sm">
    <thead>
      <tr className="text-center bg-slate-200 whitespace-nowrap">
        <th className="p-2">通貨名</th>
        <th className="p-2">価格</th>
        <th className="p-2">24H変動比</th>
      </tr>
    </thead>
    <tbody className="divide-y divide-slate-300 divide-solid">
      {props.pairs.map((pair) => {
        return (
          <TickProvider pair={pair} key={pair.name}>
            <SidebarPairsTable {...pair} />
          </TickProvider>
        )
      })}
    </tbody>
  </table>
)
