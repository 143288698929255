import PropTypes from 'prop-types'
import React from 'react'

import {
  subscribeChatPrediction,
  unsubscribeChatPrediction,
} from '../../socket'

export default class PredictionBar extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    prediction: PropTypes.shape({
      upvoteRate: PropTypes.number,
      downvoteRate: PropTypes.number,
    }),
    mode: PropTypes.oneOf(['pc', 'phone']),
  }

  constructor(props) {
    super(props)

    this.state = props.prediction
    this.handleChatPredictionChange = this.handleChatPredictionChange.bind(this)
  }

  componentDidMount() {
    const code = this.props.pair.underscored_code
    subscribeChatPrediction(code, this.handleChatPredictionChange)
  }

  componentWillUnmount() {
    const code = this.props.pair.underscored_code
    unsubscribeChatPrediction(code)
  }

  handleChatPredictionChange(prediction) {
    this.setState(prediction)
  }

  renderPC() {
    const { pair } = this.props
    const { upvoteRate, downvoteRate } = this.state

    return (
      <div id="prediction_bar">
        <div className="flex flex-row mt-2">
          <div className="basis-1/12 mr-3">
            <div className="rounded-sm shadow bg-white text-sm text-center">
              上昇
              <br />
              <span className="text-buy font-bold text-lg">{upvoteRate}</span>%
            </div>
          </div>
          <div className="basis-10/12">
            <div className="relative flex items-center h-full text-center border border-solid border-slate-300 py-1">
              <div
                className="bg-buy h-full"
                style={{ width: `${upvoteRate}%` }}
              ></div>
              <div
                className="bg-sell h-full"
                style={{ width: `${downvoteRate}%` }}
              ></div>
              <div className="absolute inset-x-0 z-20 text-white text-lg">
                {pair.name}({pair.product_name})24時間予想
              </div>
            </div>
          </div>
          <div className="basis-1/12 ml-3">
            <div className="rounded-sm shadow bg-white text-sm text-center">
              下落
              <br />
              <span className="text-sell font-bold text-lg">
                {downvoteRate}
              </span>
              %
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPhone() {
    const { pair } = this.props
    const { upvoteRate, downvoteRate } = this.state

    return (
      <div id="prediction_bar">
        <div className="flex flex-row mt-3">
          <div className="basis-2/12 mr-1">
            <div className="text-center mt-1">
              上昇
              <br />
              <span className="text-buy font-bold text-lg">{upvoteRate}</span>%
            </div>
          </div>
          <div className="basis-8/12">
            <div className="relative flex items-center h-full text-center border border-solid border-slate-300 py-0.5 mt-1">
              <div
                className="bg-buy h-full"
                style={{ width: `${upvoteRate}%` }}
              ></div>
              <div
                className="bg-sell h-full"
                style={{ width: `${downvoteRate}%` }}
              ></div>
              <div className="absolute inset-x-0 z-20 text-white">
                {pair.product_name} 24時間予想
              </div>
            </div>
          </div>
          <div className="basis-2/12 ml-1">
            <div className="text-center mt-1">
              下落
              <br />
              <span className="text-sell font-bold text-lg">
                {downvoteRate}
              </span>
              %
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.props.mode == 'pc' ? this.renderPC() : this.renderPhone()
  }
}
