import PropTypes from 'prop-types'
import React from 'react'

import PriceFormatter from '../../../lib/formatters/PriceFormatter'
import CompanyNameLink from '../../shared/CompanyNameLink'
import ColoredPrice from '../../shared/ColoredPrice'

const ExchangerRate = (props) => {
  const {
    pair,
    eventTrackingAction,
    company,
    showPairName,
    tick,
    bidHighlight,
    askHighlight,
    mode,
  } = props

  const code = pair.underscored_code.toLowerCase()

  const formatOptions = { round: pair.precision, format: pair.convert_to }
  const formattedBid = new PriceFormatter(tick.bid, formatOptions).result()
  const formattedAsk = new PriceFormatter(tick.ask, formatOptions).result()
  const formattedSpread = new PriceFormatter(
    tick.ask - tick.bid,
    formatOptions
  ).result()
  const formattedVolume = new PriceFormatter(tick.volume, {
    unit: tick.volume ? pair.base : '',
  }).result()

  return mode == 'pc' ? (
    <tr className="h-8" title={`${code}_${company}`}>
      <td className="p-2 text-blue-700 hover:opacity-70">
        <CompanyNameLink
          pair={pair}
          eventTrackingAction={eventTrackingAction}
          companyName={company}
          showPairName={showPairName}
          mode={mode}
          enableOutboundHint={true}
        />
      </td>
      <ColoredPrice
        price={tick.bid}
        formattedPrice={formattedBid}
        highlightType={bidHighlight ? 'bid' : ''}
      />
      <ColoredPrice
        price={tick.ask}
        formattedPrice={formattedAsk}
        highlightType={askHighlight ? 'ask' : ''}
      />
      <td className="p-2 text-right proportional-nums">{formattedSpread}</td>
      <td className="text-right p-2 proportional-nums">
        <span>{formattedVolume}</span>
      </td>
    </tr>
  ) : (
    <tr title={`${pair}_${company}`} className="text-sm">
      <td className="p-1 text-blue-700">
        <CompanyNameLink
          pair={pair}
          eventTrackingAction={eventTrackingAction}
          companyName={company}
          showPairName={showPairName}
          mode={mode}
          enableOutboundHint={true}
        />
      </td>
      <ColoredPrice
        price={tick.bid}
        formattedPrice={formattedBid}
        highlightType={bidHighlight ? 'bid' : ''}
      />
      <ColoredPrice
        price={tick.ask}
        formattedPrice={formattedAsk}
        highlightType={askHighlight ? 'ask' : ''}
      />
      <td className="p-1 text-right proportional-nums">
        <span>{formattedSpread}</span>
        <br />
        <span className="whitespace-nowrap">{formattedVolume}</span>
      </td>
    </tr>
  )
}

ExchangerRate.propTypes = {
  pair: PropTypes.object,
  eventTrackingAction: PropTypes.string,
  company: PropTypes.string,
  showPairName: PropTypes.bool,
  tick: PropTypes.shape({
    bid: PropTypes.number,
    ask: PropTypes.number,
    volume: PropTypes.number,
  }),
  bidHighlight: PropTypes.bool,
  askHighlight: PropTypes.bool,
  mode: PropTypes.string,
}

export default ExchangerRate
