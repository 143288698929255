import PropTypes from 'prop-types'
import React from 'react'
import Select, { components } from 'react-select'
import CoinIcon from '../shared/CoinIcon'

const SingleValue = (pair) => (props) => {
  if (!pair) {
    return null
  }

  return (
    <components.SingleValue {...props}>
      <CoinIcon coinName={pair.base} />
      {pair.product_name}
    </components.SingleValue>
  )
}

const { Option } = components
const IconOption = (props) => (
  <Option {...props}>
    <CoinIcon coinName={props.data.value.underscored_code.split('_')[0]} />
    {props.data.label}
  </Option>
)

export default class PairSelector extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    pairList: PropTypes.arrayOf(PropTypes.object),
    selectedPair: PropTypes.object,
    resource: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.state = {
      isMenuOpen: false,
      selectedPair: props.selectedPair,
    }
  }

  handleChange(e) {
    this.setState({ selectedPair: e.value })
    window.location.href = `/pair/${e.value.underscored_code.toUpperCase()}${
      this.props.resource
    }`
  }

  handleMenuOpen() {
    this.setState({
      isMenuOpen: true,
    })
  }

  handleMenuClose() {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    const selectedPairCode = this.props.selectedPair.underscored_code

    const pairOptions = this.props.pairList.map((pair) => {
      const name = pair.product_name

      return { value: pair, label: name }
    })
    const currentPair = pairOptions.reduce(
      (acc, option) =>
        option.value.underscored_code == selectedPairCode ? option : acc,
      pairOptions[0]
    )

    const optionStyles = {
      option: (styles, { isSelected }) => {
        return {
          color: '#334155',
          borderBottom: '1px solid #eee',
          padding: 10,
          backgroundColor: isSelected ? '#e2e9ed' : null,
        }
      },
    }

    const displayedPair = this.state.isMenuOpen ? null : this.state.selectedPair

    return (
      <Select
        onChange={this.handleChange}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        options={pairOptions}
        components={{
          Option: IconOption,
          SingleValue: SingleValue(displayedPair),
        }}
        styles={optionStyles}
        value={currentPair}
      />
    )
  }
}
