export const rangeUnitMap = {
  '1h': {
    unit: 60 * 1000,
    range: '01m',
    amount: 60,
  },
  '1d': {
    unit: 30 * 60 * 1000,
    range: '30m',
    amount: 48,
  },
  '1w': {
    unit: 4 * 60 * 60 * 1000,
    range: '04h',
    amount: 42,
  },
  '1M': {
    unit: 24 * 60 * 60 * 1000,
    range: 'daily',
    amount: 30,
  },
  '3M': {
    unit: 24 * 60 * 60 * 1000,
    range: 'daily',
    amount: 90,
  },
  hy: {
    unit: 24 * 60 * 60 * 1000,
    range: 'daily',
    amount: 180,
  },
  '1y': {
    unit: 7 * 24 * 60 * 60 * 1000,
    range: 'weekly',
    amount: 52,
  },
  '3y': {
    unit: 30 * 24 * 60 * 60 * 1000,
    range: 'monthly',
    amount: 36,
  },
}

export const rangeBarPeriods = [
  { name: '1時間', value: '1h' },
  { name: '1日', value: '1d' },
  { name: '1週', value: '1w' },
  { name: '1ヶ月', value: '1M' },
  { name: '3ヶ月', value: '3M' },
  { name: '半年', value: 'hy' },
  { name: '1年', value: '1y' },
  { name: '3年', value: '3y' },
]
