import { convertUnits } from '../../constants/priceFormat'
import baseFormatter from './base'

export default class PriceFormatter {
  constructor(number, options = {}) {
    this.number = parseFloat(number)
    this.round = options.round || 0
    this.format = options.format
    this.currencyUnit = options.unit || ''
    this.prefix = options.prefix
    this.postfix = options.postfix
  }

  result() {
    if (!this.number) {
      return '--'
    }

    return this.priceString()
  }

  priceString() {
    const convertUnit = convertUnits[this.format] || ''
    let price = this.formattedPrice()

    if (this.prefix) {
      price = `約 ${price}`
    }

    if (this.postfix == false) {
      return `${price}${this.currencyUnit}`
    }

    return `${price}${convertUnit}${this.currencyUnit}`
  }

  formattedPrice() {
    const formatter = baseFormatter({
      round: this.round,
      format: this.format,
    })

    return formatter(this.number)
  }
}
