import React from 'react'
import LoadingIndicator from '../../shared/LoadingIndicator'
import PriceFormatter from '../../../lib/formatters/PriceFormatter'
import DeviationFormatter from '../../../lib/formatters/DeviationFormatter'
import PercentageFormatter from '../../../lib/formatters/PercentageFormatter'

function renderDeviation({ product, dev, devPct }) {
  if (!dev || !devPct) {
    return
  }

  const deviation = product[dev]
  const deviationPercent = product[devPct]
  const formattedDeviationRate = new PercentageFormatter(
    deviationPercent
  ).result()

  const formattedDeviation = new DeviationFormatter(deviation, {
    round: product.precision,
    format: product.convert_to,
  }).result()

  return {
    inNum: formattedDeviation,
    inRate: formattedDeviationRate,
  }
}

function sortedProducts({ products, sortKey, sortOrder = true }) {
  return products.sort((prevItem, nextItem) => {
    if (sortOrder) {
      return nextItem[sortKey] - prevItem[sortKey]
    }

    return prevItem[sortKey] - nextItem[sortKey]
  })
}

function formatTradingVolume({ mode, product, quoted, volume = 'vol_24h' }) {
  const vol = product[volume.toLowerCase()]

  if (quoted === 'BTC') {
    return new PriceFormatter(vol, {
      prefix: true,
      unit: product.unit,
    }).result()
  } else {
    const hundredMillion = 10 ** 8
    const volInHundredMils = parseFloat(vol) / hundredMillion
    const formatOptions = {
      format: 'hundredMillion',
      prefix: mode === 'DESKTOP',
      round: volInHundredMils < 1 ? 3 : 0,
      unit: product.unit,
    }

    return new PriceFormatter(vol, formatOptions).result()
  }
}

function emptyPlaceholder({ colSpan = 6, pageName, timeUnit }) {
  const renderOptions = {
    値上がり率: '値上がり率',
    値下がり率: '値下がり率',
    高値更新: '高値',
    安値更新: '安値',
  }

  return (
    <tr>
      <td colSpan={colSpan} className="text-center h-36">
        <span>
          {`直近${timeUnit}で${renderOptions[pageName]}を更新している通貨はありません`}
        </span>
      </td>
    </tr>
  )
}

function loadingPlaceholder({ colSpan = 6 }) {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center h-36">
        <LoadingIndicator />
      </td>
    </tr>
  )
}

const Util = {
  emptyPlaceholder,
  formatTradingVolume,
  loadingPlaceholder,
  renderDeviation,
  sortedProducts,
}

export default Util
