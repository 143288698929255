import React from 'react'
import i18n from './i18n'

const currentClass = (currentPath, pathToMatch) => {
  if (currentPath.includes(pathToMatch)) {
    return {
      icon: 'text-red-500',
      text: '',
    }
  } else {
    return {
      icon: '',
      text: 'text-blue-700 hover:opacity-70',
    }
  }
}

const renderTime = (timeUnit) => {
  return i18n.renderLocalized(timeUnit, i18n.localTimeFull)
}

const renderQuoted = (quoted) => {
  return i18n.renderLocalized(quoted, i18n.localQuoted)
}

const CurrencyRankingHead = (props) => {
  return (
    <>
      <h1 className="text-xl md:text-2xl font-bold mb-3 mt-0">
        {`${renderTime(props.timeUnit)}の${
          props.pageName
        }ランキング：対${renderQuoted(props.quoted)}通貨ペア`}
      </h1>
      <div className="p-4 rounded-lg shadow bg-white">
        <h3 className="text-lg flex items-center">
          <img className="h-3.5 w-3.5 mx-1" alt="" src={props.btcIconPath} />
          暗号資産ランキング指標
        </h3>
        <ul className="flex flex-wrap md:flex-row text-sm md:mt-2">
          <li className="mx-1 mt-2 md:mt-0">
            <a
              href={`/ranking/deviation/up/quoted/${props.quoted}/interval/${props.timeUnit}`}
              className={`${
                currentClass(props.currentPath, '/ranking/deviation/up').text
              }`}
            >
              <span
                className={`icon-triangle-right ${
                  currentClass(props.currentPath, '/ranking/deviation/up').icon
                }`}
              ></span>
              値上がり率
            </a>
          </li>
          <li className="mx-1 mt-2 md:mt-0">
            <a
              href={`/ranking/deviation/down/quoted/${props.quoted}/interval/${props.timeUnit}`}
              className={`${
                currentClass(props.currentPath, '/ranking/deviation/down').text
              }`}
            >
              <span
                className={`icon-triangle-right ${
                  currentClass(props.currentPath, '/ranking/deviation/down')
                    .icon
                }`}
              ></span>
              値下がり率
            </a>
          </li>
          <li className="mx-1 mt-2 md:mt-0">
            <a
              href={`/ranking/high/quoted/${props.quoted}/interval/${props.timeUnit}`}
              className={`${
                currentClass(props.currentPath, '/ranking/high').text
              }`}
            >
              <span
                className={`icon-triangle-right ${
                  currentClass(props.currentPath, '/ranking/high').icon
                }`}
              ></span>
              高値更新
            </a>
          </li>
          <li className="mx-1 mt-2 md:mt-0">
            <a
              href={`/ranking/low/quoted/${props.quoted}/interval/${props.timeUnit}`}
              className={`${
                currentClass(props.currentPath, '/ranking/low').text
              }`}
            >
              <span
                className={`icon-triangle-right ${
                  currentClass(props.currentPath, '/ranking/low').icon
                }`}
              ></span>
              安値更新
            </a>
          </li>
          <li className="mx-1 mt-2 md:mt-0">
            <a
              href={`/ranking/trading_volume/quoted/${props.quoted}/interval/${props.timeUnit}`}
              className={`${
                currentClass(props.currentPath, '/ranking/trading_volume').text
              }`}
            >
              <span
                className={`icon-triangle-right ${
                  currentClass(props.currentPath, '/ranking/trading_volume')
                    .icon
                }`}
              ></span>
              売買代金
            </a>
          </li>
        </ul>
      </div>
      <p className="text-xs md:text-sm mt-2 text-right mb-8">
        時価総額ランキングは「
        <a
          href={props.pairIndexPath}
          className="text-blue-700 hover:opacity-70"
        >
          レート一覧
        </a>
        」から確認できます
      </p>
    </>
  )
}

export default CurrencyRankingHead
