import PropTypes from 'prop-types'
import React from 'react'

export default class AreaSwitchButton extends React.Component {
  static propTypes = {
    areas: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        aka: PropTypes.string,
        default: PropTypes.bool,
      })
    ),
    areaSelected: PropTypes.number,
    type: PropTypes.string,
    onSwitch: PropTypes.func,
  }

  constructor(props) {
    super(props)

    let selected = 0
    if (this.props.areaSelected == 0 || this.props.areaSelected == 1) {
      selected = this.props.areaSelected
    }
    this.state = { selected }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    if (this.state.areaSelected == e) {
      return
    }
    this.setState({ selected: e })
    this.props.onSwitch(e)
  }

  renderAreas() {
    return this.props.areas.map((area, idx) => {
      const checked = area.default == true

      return (
        <li
          className={`${
            this.props.type == 'radio'
              ? 'cursor-pointer'
              : 'before:border-l before:border-solid before:border-slate-300 before:h-6'
          }`}
          key={`areaTab-${area.aka}`}
        >
          <input
            type="radio"
            name="area"
            id={area.aka}
            className={`cursor-pointer ${
              this.props.type == 'radio' ? '' : 'peer hidden'
            }`}
            onClick={() => this.handleClick(idx)}
            defaultChecked={checked}
          />
          <label
            className={`mx-1.5 cursor-pointer ${
              this.props.type == 'radio'
                ? ''
                : 'peer-checked:bg-blue-500 peer-checked:rounded peer-checked:text-white peer-checked:py-1 peer-checked:px-2'
            }`}
            htmlFor={area.aka}
          >
            {area.text}
          </label>
        </li>
      )
    })
  }

  renderList() {
    return (
      <ul className="flex items-center my-1 text-slate-400 text-sm md:text-base">
        <li className="text-slate-500 mr-1.5">表示</li>
        {this.renderAreas()}
      </ul>
    )
  }

  renderRadio() {
    return <ul className="flex items-center gap-2">{this.renderAreas()}</ul>
  }

  render() {
    return this.props.type == 'radio' ? this.renderRadio() : this.renderList()
  }
}
