export const rangeUnitMap = {
  '01m': { unit: 60 * 1000 },
  '05m': { unit: 5 * 60 * 1000 },
  '10m': { unit: 10 * 60 * 1000 },
  '15m': { unit: 15 * 60 * 1000 },
  '30m': { unit: 30 * 60 * 1000 },
  '01h': { unit: 60 * 60 * 1000 },
  '04h': { unit: 4 * 60 * 60 * 1000 },
  daily: { unit: 24 * 60 * 60 * 1000 },
  weekly: { unit: 7 * 24 * 60 * 60 * 1000 },
  monthly: { unit: 30 * 24 * 60 * 60 * 1000 },
}

export const rangeBarPeriods = [
  { name: '1分足', value: '01m' },
  { name: '5分足', value: '05m' },
  { name: '10分足', value: '10m' },
  { name: '15分足', value: '15m' },
  { name: '30分足', value: '30m' },
  { name: '1時間足', value: '01h' },
  { name: '4時間足', value: '04h' },
  { name: '日足', value: 'daily' },
  { name: '週足', value: 'weekly' },
  { name: '月足', value: 'monthly' },
]
