import PropTypes from 'prop-types'
import React from 'react'

export default class AlertMessage extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom']),
    hide: PropTypes.bool,
    onClick: PropTypes.func,
  }

  renderMessage() {
    if (this.props.onClick) {
      return <a onClick={this.props.onClick}>{this.props.message}</a>
    } else {
      return this.props.message
    }
  }

  render() {
    const { position, hide } = this.props
    const displayClass = hide ? 'hidden' : ''

    return (
      <div
        className={`${displayClass} py-1 px-2 bg-slate-200 opacity-50 text-center chat_${position}_tip`}
      >
        {this.renderMessage()}
      </div>
    )
  }
}
