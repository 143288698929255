import baseFormatter from './base'

export default class DeviationFormatter {
  constructor(number, options = {}) {
    this.number = parseFloat(number)
    this.format = options.format
    this.round = options.round || options.round == 0 ? options.round : 2
  }

  result() {
    if (!this.number) {
      return '--'
    }

    return this.priceString()
  }

  priceString() {
    const priceDev = this.formattedDev()

    return this.number > 0 ? `+${priceDev}` : priceDev
  }

  formattedDev() {
    const formatter = baseFormatter({
      round: this.round,
      format: this.format,
    })

    return formatter(this.number)
  }
}
