import React from 'react'

const SmsAuthForm = ({ smsAuthRegistrationPath }) => {
  return (
    <div className="p-2">
      <div className="flex items-center">
        <div style={{ width: '100%' }}>
          <p className="relative bg-slate-200 p-2 rounded mr-2 text-sm md:text-xs shadow after:w-3 after:h-3 after:absolute after:bg-slate-200 after:-right-1 after:top-[40%] after:rotate-45">
            チャットを開始するまであと一歩！SMS認証をする
          </p>
        </div>
        <a
          className="w-auto whitespace-nowrap ml-1 p-2 rounded-lg border border-blue-700 border-solid bg-slate-50 shadow font-bold text-center text-blue-700 text-sm hover:opacity-70"
          href={smsAuthRegistrationPath}
        >
          SMS認証
        </a>
      </div>
    </div>
  )
}

export default SmsAuthForm
