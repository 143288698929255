import React from 'react'
import moment from 'moment-timezone'
import Api from '../../../api/api'
import I18n from '../shared/i18n'
import Head from '../shared/head'
import TabOption from '../shared/tabOption'
import DeviationRanking from './DeviationRanking'

export default class DeviationRankingTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedQuoted: props.quoted,
      selectedTimeUnit: props.timeUnit,
      selectedPairs: props.selectedPairs,
      updatedAt: moment(new Date(props.updatedAt))
        .tz('Asia/Tokyo')
        .format('YYYY/MM/DD HH:mm'),
      isLoading: false,
    }

    this.updateTab = this.updateTab.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    this.scheduledFetcher = setInterval(() => this.fetchData(), 10000)

    window.onpopstate = (event) => {
      if (window.history.state === undefined) {
        return
      }

      if (!(event.state && event.state.quoted && event.state.timeUnit)) {
        return window.location.reload()
      }

      this.updateTab({
        quoted: event.state.quoted,
        timeUnit: event.state.timeUnit,
        pushState: false,
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.scheduledFetcher)
  }

  filterByDirection(pairs) {
    const direction = this.props.direction
    const timeUnit = this.state.selectedTimeUnit.toLowerCase()

    return pairs.filter((pair) => {
      const dev = pair[`dev_${timeUnit}`]

      if (direction === 'UP') {
        return dev >= 0 && dev !== null
      } else {
        return dev <= 0 && dev !== null
      }
    })
  }

  fetchData() {
    if (document.hasFocus()) {
      Api.fetch(
        `/api/ranking/deviation/quoted/${this.state.selectedQuoted}/interval/${this.state.selectedTimeUnit}`
      ).then((response) => {
        this.setState({
          ...this.state,
          selectedPairs: response.items,
          updatedAt: moment(new Date(response.updated_at))
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm'),
          isLoading: false,
        })
      })
    }
  }

  updateTab({
    quoted = this.state.selectedQuoted,
    timeUnit = this.state.selectedTimeUnit,
    pushState = true,
  }) {
    this.setState(
      {
        ...this.state,
        selectedQuoted: quoted,
        selectedTimeUnit: timeUnit || this.state.selectedTimeUnit,
        isLoading: true,
      },
      this.fetchData
    )

    if (!pushState) {
      return
    }

    history.pushState(
      { quoted, timeUnit },
      '',
      `/ranking/deviation/${this.props.direction.toLowerCase()}/quoted/${quoted}/interval/${timeUnit}`
    )
    I18n.updatePageTitle(timeUnit, quoted)
  }

  render() {
    const timeUnit = ['24H', '7D', '30D']

    return (
      <>
        <Head
          pageName={this.props.pageName}
          quoted={this.state.selectedQuoted}
          timeUnit={this.state.selectedTimeUnit}
          currentPath={this.props.currentPath}
          btcIconPath={this.props.btcIconPath}
          pairIndexPath={this.props.pairIndexPath}
        />
        <section id="pairs-ranking" className="mb-12 md:mb-16">
          <div className="text-xs md:text-base md:flex md:justify-between mb-2">
            <p className="text-slate-500">更新日時 {this.state.updatedAt}</p>
            <div className="md:flex mt-3 md:mt-0">
              <ul className="flex justify-end pb-4 md:pb-0 md:mr-7">
                <li className="text-slate-500 mr-1 md:mr-1.5">通貨ペア</li>
                {this.props.pairLists.map(({ quoted }) => {
                  return (
                    <TabOption
                      checked={this.state.selectedQuoted == quoted}
                      onClick={() => this.updateTab({ quoted })}
                      name="quoted"
                      key={quoted}
                      text={quoted}
                    />
                  )
                })}
              </ul>
              <ul className="flex justify-end">
                <li className="text-slate-500 mr-1 md:mr-1.5">期間</li>
                {timeUnit.map((unit) => {
                  return (
                    <TabOption
                      checked={this.state.selectedTimeUnit == unit}
                      onClick={() => {
                        this.updateTab({ timeUnit: unit })
                      }}
                      name="timeUnit"
                      key={unit}
                      text={unit}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
          <DeviationRanking
            products={this.filterByDirection(this.state.selectedPairs)}
            pageName={this.props.pageName}
            quoted={this.state.selectedQuoted}
            timeUnit={this.state.selectedTimeUnit}
            sortKey={`dev_pct_${this.state.selectedTimeUnit.toLowerCase()}`.toString()}
            sortOrder={this.props.direction === 'UP'}
            isLoading={this.state.isLoading}
            mode={this.props.mode}
          />
          <p className="my-2 text-xs md:text-sm text-slate-500">
            ※
            暗号資産は24時間365日取引されており、始値、終値が取引所で異なります。その為、変動価格と変動比率に関しては、過去
            {I18n.renderLocalized(
              this.state.selectedTimeUnit,
              I18n.localTimeFull
            )}
            の価格で比較しています。
          </p>
        </section>
      </>
    )
  }
}
