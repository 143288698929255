import PropTypes from 'prop-types'
import React from 'react'

import ReportButton from './ReportButton'

const MessageItem = ({
  userId,
  username,
  time,
  reported,
  onReportClick,
  currentUser,
  type,
  children,
}) => {
  const showReportButton =
    currentUser && userId != currentUser.id && type == 'message'

  return (
    <li className={'py-1.5'}>
      <div className="text-sm">
        <span className="rounded-full py-0.5 px-1 mr-1 text-slate-400 bg-slate-100">
          ID:{userId}
        </span>
        <span className="my-1">{username}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="mx-1 text-xs text-slate-400">{time}</span>
        {showReportButton && (
          <ReportButton onClick={onReportClick} disabled={reported} />
        )}
      </div>
      {children}
    </li>
  )
}

MessageItem.propTypes = {
  id: PropTypes.number,
  userId: PropTypes.number,
  username: PropTypes.string,
  time: PropTypes.string,
  reported: PropTypes.bool,
  onReportClick: PropTypes.func,
  currentUser: PropTypes.object,
  type: PropTypes.oneOf(['message', 'prediction']),
  children: PropTypes.object,
}

export default MessageItem
