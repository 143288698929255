import PropTypes from 'prop-types'
import React from 'react'

const ExchangerRateListFrame = ({ title, mode, children }) => (
  <div>
    <h3 className="text-lg mb-2 border-b border-solid border-slate-400">
      {title}
    </h3>
    <table className="w-full text-sm md:text-base">
      {mode == 'pc' ? (
        <thead>
          <tr>
            <th className="text-left p-2 bg-slate-200 border border-solid border-white">
              取引所名
            </th>
            <th
              colSpan="1"
              className="p-2 text-center bg-slate-200 border border-solid border-white"
            >
              売値
            </th>
            <th
              colSpan="1"
              className="p-2 text-center bg-slate-200 border border-solid border-white"
            >
              買値
            </th>
            <th className="p-2 text-right bg-slate-200 border border-solid border-white">
              スプレッド
            </th>
            <th className="p-2 text-right bg-slate-200 border border-solid border-white">
              24時間の取引高
            </th>
          </tr>
        </thead>
      ) : (
        <thead>
          <tr>
            <th className="p-1 bg-slate-200 border border-solid border-white">
              取引所名
            </th>
            <th
              colSpan="1"
              className="p-1 text-center bg-slate-200 border border-solid border-white"
            >
              売値
            </th>
            <th
              colSpan="1"
              className="p-1 text-center bg-slate-200 border border-solid border-white"
            >
              買値
            </th>
            <th className="p-1 text-right bg-slate-200 border border-solid border-white">
              スプレッド
              <br />
              24H取引高
            </th>
          </tr>
        </thead>
      )}
      <tbody class="divide-y divide-slate-300 divide-solid">{children}</tbody>
    </table>
  </div>
)

ExchangerRateListFrame.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  mode: PropTypes.string,
}

export default ExchangerRateListFrame
