import PropTypes from 'prop-types'
import React from 'react'

import { getPairUnit } from '../../lib/pairHelper'
import DeviationFormatter from '../../lib/formatters/DeviationFormatter'
import CompanyNameLink from '../shared/CompanyNameLink'

export default class BestArbitrageRow extends React.Component {
  static propsType = {
    pair: PropTypes.object,
    askCompany: PropTypes.string,
    bidCompany: PropTypes.string,
    diffPrice: PropTypes.number,
    mode: PropTypes.string,
  }

  render() {
    const { askCompany, bidCompany, pair, diffPrice, mode } = this.props
    const formattedDiffPrice = new DeviationFormatter(diffPrice, {
      round: pair.precision,
      format: pair.convert_to,
    }).result()

    const unit = getPairUnit(pair)

    return mode == 'pc' ? (
      <dl className="table w-full border-y border-slate-300 border-solid h-8 mt-4 mb-8">
        <dt className="table-cell align-middle pl-2 border-l-4 border-solid border-buy">
          最適なアービトラージ
        </dt>
        <dd className="table-cell align-middle relative bg-red-100 min-w-[144px] px-6 after:w-6 after:h-6 after:absolute after:bg-red-100 after:left-[92%] after:rotate-45 after:z-10">
          <strong
            id="min_ask_company"
            className="text-blue-700 hover:opacity-70"
          >
            <CompanyNameLink
              pair={pair}
              eventTrackingAction={'Table/BestArbitrage'}
              companyName={askCompany}
              mode={mode}
              klass={'font-bold'}
            />
          </strong>
          <small>で購入</small>
        </dd>
        <dd className="table-cell align-middle relative bg-sky-200 min-w-[144px] px-6 after:w-6 after:h-6 after:absolute after:bg-sky-200 after:left-[92%] after:rotate-45">
          <strong
            id="max_bid_company"
            className="text-blue-700 hover:opacity-70"
          >
            <CompanyNameLink
              pair={pair}
              eventTrackingAction={'Table/BestArbitrage'}
              companyName={bidCompany}
              mode={mode}
              klass={'font-bold'}
            />
          </strong>
          <small>で売却</small>
        </dd>
        <dt className="table-cell align-middle pl-6">
          <small>価格差</small>
        </dt>
        <dd className="table-cell align-middle font-bold min-w-[100px] text-right">
          <strong id="max_diff_price" className="text-2xl text-buy font-bold">
            {formattedDiffPrice}
          </strong>
          {unit}
          <i className="icon-revenueamount" />
        </dd>
      </dl>
    ) : (
      <div className="mb-4">
        <dl className="table w-full border-l-4 border-solid border-buy">
          <dt className="p-1 table-cell align-middle">最適なアービトラージ</dt>
          <dt className="p-1 table-cell align-middle">
            <small>価格差</small>
          </dt>
          <dd className="p-1 table-cell align-middle text-right">
            <strong id="max_diff_price" className="text-2xl text-buy font-bold">
              {formattedDiffPrice}
            </strong>
            {unit}
            <i className="icon-revenueamount" />
          </dd>
        </dl>
        <dl className="flex w-full border-l-4 border-solid border-buy">
          <dd className="flex items-center align-middle relative px-5 bg-red-100 h-8 after:w-6 after:h-6 after:absolute after:bg-red-100 after:left-[92%] after:rotate-45 after:z-10">
            <strong id="min_ask_company" className="text-blue-700">
              <CompanyNameLink
                pair={pair}
                eventTrackingAction={'Table/BestArbitrage'}
                companyName={askCompany}
                mode={mode}
                klass={'font-bold'}
              />
            </strong>
            <small>で購入</small>
          </dd>
          <dd className="flex items-center align-middle relative bg-sky-200 px-5 after:w-6 after:h-6 after:absolute after:bg-sky-200 after:left-[92%] after:rotate-45">
            <strong id="max_bid_company" className="text-blue-700">
              <CompanyNameLink
                pair={pair}
                eventTrackingAction={'Table/BestArbitrage'}
                companyName={bidCompany}
                mode={mode}
                klass={'font-bold'}
              />
            </strong>
            <small>で売却</small>
          </dd>
        </dl>
      </div>
    )
  }
}
