import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import Textarea from 'react-textarea-autosize'

import chatApi from '../../../api/chat'
import { getChatMessageLength } from '../../../lib/chatHelper'

const CHAT_ROOM = 1
const MAX_MESSAGE_LENGTH = 140
const MAX_URL_LENGTH = 23
const UNLOGGED_IN_MESSAGE = 'アカウント登録もしくはログインしてください.'
const IS_BANNED_MESSAGE =
  'お客様は現在書き込みを行うことができません。利用規約をご確認ください。'

const alertFailure = (response) => {
  if (response.status == 401) {
    alert(UNLOGGED_IN_MESSAGE)
  } else if (response.status == 403) {
    alert(IS_BANNED_MESSAGE)
  }
}

const handleCatch = (event) => {
  if (!(event instanceof Error)) {
    alertFailure(event)
  }
}

const NicknameEditButton = ({ setEditing }) => (
  <button
    className="cursor-pointer text-indigo-700 py-1 whitespace-nowrap"
    onClick={() => setEditing(true)}
  >
    変更
  </button>
)

const NicknameSubmitButton = ({ id, nickname, setEditing }) => {
  const updateNickname = useCallback((id, nickname) => {
    chatApi.updateNickname(id, nickname).catch(handleCatch)
    setEditing(false)
  })

  return (
    <button
      className="nickname-submit"
      onClick={() => updateNickname(id, nickname)}
    >
      決定
    </button>
  )
}

const LoggedInForm = ({ user }) => {
  const [nickname, setNickname] = useState(user.nickname)
  const [nicknameEditing, setNicknameEditing] = useState(false)
  const [message, setMessage] = useState('')

  const validateMessage = useCallback((e) => {
    const messageInput = e.target.value.replace('\n', '')
    if (
      getChatMessageLength(messageInput, MAX_URL_LENGTH) <= MAX_MESSAGE_LENGTH
    ) {
      setMessage(messageInput)
    }
  })

  const sendMessage = useCallback(() => {
    chatApi.sendMessage(CHAT_ROOM, message).catch(handleCatch)
    setMessage('')
  })

  const inputRef = useRef(null)
  useEffect(() => {
    if (nicknameEditing) {
      inputRef.current.focus()
    }
  }, [nicknameEditing])

  return (
    <div className="px-4 py-1.5">
      <div className="flex justify-between border-b border-slate-300 border-solid">
        <input
          ref={inputRef}
          type="text"
          className={`my-1 py-0.5 px-2 bg-white text-sm ${
            nicknameEditing
              ? 'border border-solid border-slate-300 rounded'
              : ''
          }`}
          placeholder="はじめにユーザーネームを設定します"
          defaultValue={nickname}
          onChange={(e) => setNickname(e.target.value)}
          readOnly={!nicknameEditing}
        />
        {nicknameEditing ? (
          <NicknameSubmitButton
            id={user.id}
            nickname={nickname}
            setEditing={setNicknameEditing}
          />
        ) : (
          <NicknameEditButton setEditing={setNicknameEditing} />
        )}
      </div>
      <div
        className={`py-2 flex items-center justify-between ${
          nickname.length != 0 ? '' : 'hidden'
        }`}
      >
        <Textarea
          type="text"
          className="pl-1 resize-none w-3/4"
          placeholder="ここにメッセージを入力"
          rows="1"
          value={message}
          onChange={(e) => validateMessage(e)}
          onKeyPress={(e) => {
            if (e.key == 'Enter') {
              sendMessage()
            }
          }}
        />
        <div className="">
          <button
            className="disabled:opacity-50 block p-2 text-white bg-red-600 rounded-lg shadow-sm shadow-black/30 hover:shadow-none hover:relative hover:top-px"
            type="submit"
            disabled={message.length == 0 || nicknameEditing}
            onClick={() => sendMessage()}
          >
            送信
          </button>
        </div>
      </div>
    </div>
  )
}

LoggedInForm.propTypes = {
  user: PropTypes.object,
}

export default LoggedInForm
