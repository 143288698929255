import React from 'react'

export default class PairHeading extends React.Component {
  constructor(props) {
    super(props)

    this.renderNameByDevice = this.renderNameByDevice.bind(this)
  }

  renderNameByDevice() {
    const product = this.props.product

    if (this.props.mode === 'MOBILE') {
      return product.base
    }

    return (
      <React.Fragment>
        <span className="mr-2">{product.base_currency_name}</span>
        <span className="break-keep">
          (<abbr title={product.display_name}>{product.base}</abbr>)
        </span>
      </React.Fragment>
    )
  }

  render() {
    return (
      <h2 className="text-sm md:text-base hover:opacity-70 whitespace-nowrap">
        <a href={`/pair/${this.props.product.underscored_code}`}>
          {this.renderNameByDevice()}
        </a>
      </h2>
    )
  }
}
