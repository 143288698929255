import PropTypes from 'prop-types'
import React from 'react'

const ReportButton = ({ onClick, disabled }) => (
  <span className={`${disabled ? 'opacity-50' : ''}`}>
    <span
      className="px-px py-0.5 border border-solid border-slate-300 rounded text-slate-500 bg-slate-100 hover:relative hover:top-px"
      onClick={disabled ? null : onClick}
    >
      <i className="icon-police2 text-sm" />
    </span>
  </span>
)

ReportButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ReportButton
