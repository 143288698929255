import api from './api'

const parseBarToArray = (bar) => [
  bar[0],
  parseFloat(bar[1]),
  parseFloat(bar[2]),
  parseFloat(bar[3]),
  parseFloat(bar[4]),
]

export default {
  getBar: (pair, range, limit) =>
    api
      .fetch(`/api/bar/${pair}.json`, { range, limit })
      .then((array) => array.map(parseBarToArray)),
}
