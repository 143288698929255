import React from 'react'
import PairMarket from './PairMarket'
import Api from '../../api/api'

import AreaSwitchButton from '../shared/AreaSwitchButton'

export default class PairMarketsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: props.categories[0].key,
      selectedPairs: props.categories[0].pairs,
      areaSelected: 0,
    }

    this.updateSelectedTab = this.updateSelectedTab.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.handleAreaSwitch = this.handleAreaSwitch.bind(this)
  }

  updateSelectedTab(key) {
    const selectedCategory = this.props.categories.find(
      (category) => category.key == key
    )
    this.setState({
      ...this.state,
      selectedTab: key,
      selectedPairs: selectedCategory.pairs,
    })
  }

  componentDidMount() {
    this.scheduledFetcher = setInterval(() => this.fetchData(), 3000)
  }

  componentWillUnmount() {
    clearInterval(this.scheduledFetcher)
  }

  fetchData() {
    if (document.hasFocus()) {
      Api.fetch(`/api/pair/${this.state.selectedTab}`).then((response) => {
        this.setState({
          ...this.state,
          selectedPairs: response,
        })
      })
    }
  }

  handleAreaSwitch(e) {
    this.setState({ areaSelected: e })
  }

  renderTabs() {
    return this.props.categories.map(({ name, key }, index) => [
      <a
        href={`#currencies_tables_${index}`}
        className={`block mr-1 py-2 px-4 font-bold text-sm md:text-base ${
          this.state.selectedTab == key
            ? 'bg-white rounded-t-sm border-t-4 border-solid border-blue-500'
            : 'text-blue-700 rounded-t-sm border-t-0 bg-slate-200 hover:rounded-t-sm hover:border-t-4 hover:border-solid hover:border-blue-500 hover:bg-white'
        }`}
        onClick={() => this.updateSelectedTab(key)}
        key={key}
      >
        {name}
      </a>,
    ])
  }

  currentPairs() {
    const currentArea = this.props.areas[this.state.areaSelected].aka

    if (currentArea == 'incountry') {
      return this.state.selectedPairs.filter((pair) => {
        return this.props.inCountryPairCodes.includes(pair.base)
      })
    }

    return this.state.selectedPairs
  }

  render() {
    return (
      <section id="pair-rate">
        <div className="flex justify-between mt-3 flex-col-reverse md:flex-row">
          <div className="flex items-end -ml-1.5 md:ml-0">
            {this.renderTabs()}
          </div>
          <div className="flex items-center mb-2 md:mb-0">
            <AreaSwitchButton
              areas={this.props.areas}
              onSwitch={this.handleAreaSwitch}
            />
          </div>
        </div>

        <div className="md:p-4 md:rounded-b-lg md:rounded-tr-lg shadow bg-white -mx-4 md:mx-0">
          <PairMarket products={this.currentPairs()} mode={this.props.mode} />
        </div>
        <p className="my-2 text-xs md:text-sm text-slate-500">
          ※1Satoshi＝0.00000001BTC
        </p>
      </section>
    )
  }
}
