import PropTypes from 'prop-types'
import React from 'react'

import PriceFormatter from '../../../lib/formatters/PriceFormatter'
import PercentageFormatter from '../../../lib/formatters/PercentageFormatter'
import CoinIcon from '../../shared/CoinIcon'
import TickProvider from '../../shared/TickProvider'
import { parsePriceColor, deviationPct } from '../../../lib/pairHelper'

class MiniRateChartHead extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    mode: PropTypes.string,
    lastPrice: PropTypes.number,
    lastBid: PropTypes.number,
    dayAgoCloseBid: PropTypes.number,
  }

  renderTwitterButton(pair) {
    return (
      <a
        href={`/pair/${pair.underscored_code}/tweet`}
        target="_blank"
        className="w-7 h-7 p-0 bg-slate-200 block rounded text-center hover:bg-sky-400"
        aria-label="tweet"
      >
        <i className="icon-twitter align-middle text-xl text-white"></i>
      </a>
    )
  }

  render() {
    const { pair, mode, lastPrice, lastBid, dayAgoCloseBid } = this.props
    const code = pair.underscored_code.toLowerCase()
    const priceDeviation = lastBid - dayAgoCloseBid
    const bgStatus = parsePriceColor(priceDeviation)

    const formatOptions = { round: pair.precision, format: pair.convert_to }
    const formattedPrice = new PriceFormatter(lastPrice, formatOptions).result()
    const formattedDevPct = new PercentageFormatter(
      deviationPct(lastBid, dayAgoCloseBid)
    ).result()

    if (mode == 'pc') {
      return (
        <div className="px-4 pt-4 border-b border-solid border-slate-300">
          <a href={`/pair/${pair.underscored_code}`} className="flex mb-1">
            <h3 className="flex flex-1 relative overflow-hidden whitespace-nowrap flex-col justify-around after:absolute text-blue-700 hover:opacity-70">
              {pair.base_currency_name}
            </h3>
            <div className="text-center text-blue-700 hover:opacity-70">
              <CoinIcon coinName={pair.base} />
              <abbr
                className="text-sm leading-none block -mt-1"
                title={pair.eng_name}
              >
                {pair.base}
              </abbr>
            </div>
          </a>

          <div className="flex justify-end pb-2">
            <span
              id={`ltp_${code}`}
              className="text-sm whitespace-nowrap proportional-nums tracking-wide"
            >
              {formattedPrice}
              <span
                className={`whitespace-nowrap proportional-nums text-sm ${bgStatus}`}
              >
                {`(${formattedDevPct})`}
              </span>
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="px-4 pt-4 pb-2 border-b border-solid border-slate-300">
          <h3 className="text-lg whitespace-nowrap overflow-hidden relative after:absolute after:top-0 after:right-0 after:w-1/3 after:h-full after:bg-gradient-to-l after:from-white text-blue-700">
            <a href={`/pair/${pair.underscored_code}`}>
              {pair.base_currency_name}
            </a>
          </h3>
          <div className="flex justify-start">
            <div className="flex flex-col justify-around text-blue-700">
              <a href={`/pair/${pair.underscored_code}`}>
                <CoinIcon coinName={pair.base} />
                <abbr title={pair.eng_name}>{pair.base}</abbr>
              </a>
            </div>
          </div>
          <div className="mt-1 text-right">
            <span
              id={`ltp_${code}`}
              className="whitespace-nowrap proportional-nums tracking-wide"
            >
              {formattedPrice}
            </span>
            <br />
            <span
              className={`whitespace-nowrap proportional-nums text-sm ${bgStatus}`}
            >
              {`(${formattedDevPct})`}
            </span>
          </div>
        </div>
      )
    }
  }
}

export default (props) => (
  <TickProvider pair={props.pair}>
    <MiniRateChartHead {...props} />
  </TickProvider>
)
