import PropTypes from 'prop-types'
import React from 'react'

export default class ColoredPrice extends React.Component {
  static propTypes = {
    price: PropTypes.number,
    formattedPrice: PropTypes.string,
    highlightType: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { variation: null }
    this.lastUpdateTime = Date.now()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.price) {
      return
    }
    const diffPrice = this.props.price - prevProps.price

    if (diffPrice > 0) {
      this.setVariation('up')
    } else if (diffPrice < 0) {
      this.setVariation('down')
    } else if (
      this.state.variation &&
      Date.now() - this.lastUpdateTime >= 1000
    ) {
      this.setVariation(null)
    }
  }

  setVariation(variation) {
    this.lastUpdateTime = Date.now()
    this.setState({ ...this.state, variation })
  }

  getPriceColor() {
    switch (this.state.variation) {
      case 'up':
        return 'text-plus'
      case 'down':
        return 'text-minus'
      default:
        return ''
    }
  }

  getBackgroundColor() {
    switch (this.props.highlightType) {
      case 'bid':
        return 'bg-sky-200'
      case 'ask':
        return 'bg-red-100'
      default:
        return ''
    }
  }

  render() {
    const { formattedPrice } = this.props
    const colorClass = this.getPriceColor()
    const backgroundClass = this.getBackgroundColor()

    return (
      <td
        className={`proportional-nums whitespace-nowrap p-1 md:p-2 text-right ${colorClass} ${backgroundClass}`}
        key="price"
      >
        {formattedPrice}
      </td>
    )
  }
}
