import IntlPolyfill from 'intl'
import 'intl/locale-data/jsonp/en-US'
import { convertRates } from '../../constants/priceFormat'

const validateFormat = (format) => {
  const avaliableFormats = Object.keys(convertRates)
  const validFormat = avaliableFormats.includes(format)

  if (format && !validFormat) {
    throw new Error(`Valid formats: ${avaliableFormats} (input: ${format})`)
  }
}

export default (options) => {
  const format = options.format
  validateFormat(format)
  IntlPolyfill.__disableRegExpRestore()

  const convertRate = format ? convertRates[format] : 0
  const precision = !options.round || format === 'Satoshi' ? 0 : options.round

  const formatter = IntlPolyfill.NumberFormat('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })

  return (value) => {
    const valInSatoshi = parseFloat(value) * 10 ** convertRate
    const valInConvertRate = parseFloat(value) / 10 ** convertRate

    if (format === 'Satoshi') {
      return formatter.format(valInSatoshi)
    }

    return formatter.format(valInConvertRate)
  }
}
